/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Button, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
export default function Header() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Button block className="card-stats mb-4 mb-xl-0" to="/painel/meu-resultado" tag={Link} >
                  <CardBody>
                    <Row>
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="ni ni-favourite-28" />
                      </div>
                      <div className="col">
                        <span className="h2 font-weight-bold mb-0"> {t('meuResultado')}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Button> 
              </Col>
              <Col lg="6" xl="3">
                <Button block className="card-stats mb-4 mb-xl-0" to="/painel/relatorios" tag={Link} >
                  <CardBody>
                    <Row>
                      <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                        <i className="ni ni-chart-bar-32" />
                      </div>
                      <div className="col">
                        <span className="h2 font-weight-bold mb-0">{t('meusRelatorios')}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Button>
              </Col>
              <Col lg="6" xl="3">
                <Button block className="card-stats mb-4 mb-xl-0" to="/planos" tag={Link} >
                  <CardBody>
                    <Row>
                      <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                        <i className="ni ni-credit-card" />
                      </div>
                      <div className="col">
                        <span className="h2 font-weight-bold mb-0">{t('planos')}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Button>
              </Col>
              <Col lg="6" xl="3">
                <Button block className="card-stats mb-4 mb-xl-0" to="/painel/perfil" tag={Link}>
                  <CardBody>
                    <Row>
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i className="ni ni-badge" />
                      </div>
                      <div className="col">
                        <span className="h2 font-weight-bold mb-0">{t('perfil')}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
