import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Login from "../Auth";
import Registrar from "../Registrar";
import LogoIconeAcima from "../../assets/img/img_site/LogoIconeAcima.png";
import Context from "../../utils/context";

import "./styles.css";
import "./magnific-popup.css";

function Header() {
  const context = useContext(Context);
  const { t, i18n } = useTranslation();
  const [flagActive, setFlagActive] = useState("brazil");
  useEffect(() => {
    function changeFlags() {
      const lng = i18n.languages[0];
      console.log("lng", lng);
      if (lng === "en") setFlagActive("united-states");
      else if (lng === "es") setFlagActive("spain");
      else setFlagActive("brazil");
    }
    changeFlags();
  }, [i18n.languages]);

  function chengeLabel(type) {
    if (type === 0) {
      return context.isAuthenticated() ? t("sair") : t("entrar");
    } else {
      return context.isAuthenticated() ? t("painel") : t("registreSe");
    }
  }

  function chengeHref(type) {
    if (type === 0) {
      return context.isAuthenticated() ? "/logout" : "/login";
    } else {
      return context.isAuthenticated() ? "/painel/index" : "/registro";
    }
  }

  function handleClick(e, lang) {
    e.preventDefault();
    i18n.changeLanguage(lang);
  }

  return (
    <>
      <header>
        <div className="header-area">
          <div id="sticky-header" className="main-header-area">
            <div className="container-fluid p-0">
              <div className="row align-items-center no-gutters">
                <div className="col-xl-2 col-lg-2">
                  <div className="logo-img">
                    <a href="/">
                      <img src={LogoIconeAcima} alt="" width={150} />
                    </a>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="main-menu  d-none d-lg-block">
                    <nav>
                      <ul id="navigation">
                        <li>
                          <a href="/">{t("inicio")}</a>
                        </li>
                        <li>
                          <a href="/inicia-teste">{t("fazeroteste")}</a>
                        </li>
                        <li>
                          <a
                            className=" dropdown-toggle"
                            href="hide-link"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {t("depoimentos")}
                          </a>
                          <div
                            className="dropdown-menu"
                            style={{ paddingLeft: 10 }}
                            aria-labelledby="dropdownMenuLink"
                          >
                            <a className="dropdown-item" href="/depoimentos">
                              {t("verDepoimentos")}
                            </a>
                            <a
                              className="dropdown-item"
                              href="/novo-depoimento"
                            >
                              {t("deixarUmDepoimento")}
                            </a>
                          </div>
                        </li>
                        <li>
                          <a href="/sobre-nos">{t("sobreNos")}</a>
                        </li>
                        <li>
                          <a href="/contato">{t("contato")}</a>
                        </li>
                        <li>
                          <a href={chengeHref(0)} className="hide-link">
                            {chengeLabel(0)}
                          </a>
                        </li>
                        <li>
                          <a href={chengeHref(1)} className="hide-link">
                            {chengeLabel(1)}
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                  <div className="log_chat_area d-flex align-items-center">
                    <div className="lngSelector dropdown show">
                      <a
                        className=" dropdown-toggle"
                        href="hide-link"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={`/img/svg/${flagActive}.svg`}
                          width={30}
                          alt="Kiwi standing on oval"
                        ></img>
                      </a>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <a
                          className="dropdown-item"
                          href="hide-link"
                          onClick={(e) => handleClick(e, "pt")}
                        >
                          <img
                            src="/img/svg/brazil.svg"
                            width={30}
                            alt="pt"
                          ></img>
                        </a>
                        <a
                          className="dropdown-item"
                          href="hide-link"
                          onClick={(e) => handleClick(e, "en")}
                        >
                          <img
                            src="/img/svg/united-states.svg"
                            width={30}
                            alt="en"
                          ></img>
                        </a>
                        <a
                          className="dropdown-item"
                          href="hide-link"
                          onClick={(e) => handleClick(e, "es")}
                        >
                          <img
                            src="/img/svg/spain.svg"
                            width={30}
                            alt="es"
                          ></img>
                        </a>
                      </div>
                    </div>
                    <a href={chengeHref(0)} className="login">
                      {/* <i className="flaticon-user"></i> */}
                      <span>{chengeLabel(0)}</span>
                    </a>
                    <div className="live_chat_btn">
                      <a className="boxed_btn_green" href={chengeHref(1)}>
                        <i className="flaticon-chat"></i>
                        <span>{chengeLabel(1)}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile_menu d-block d-lg-none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;

//{<Login/>
// <Registrar/>
