import React, {useEffect, useState} from 'react'
import md5 from 'md5'
export default function Md5(){
    const [ meuMd5, setMeuMd5 ] = useState('')
    useEffect(()=>{
        const _m = md5("Um teste#teste@teste.compersonatest");
        setMeuMd5(_m)
    },[])
    return(
        <>
            <h1>{meuMd5}</h1>
        </>
    );
}