import React,{useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import logo from    '../../assets/img/logo.png';
import Context from "../../utils/context";
import { useLocation } from 'react-router-dom'


import './styles.css'

export default function Login({history}){
    const context = useContext(Context)
    const { t, i18n } = useTranslation();
    const [ email, setEmial] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ registroLink, setRegistroLink ] = useState('/registro');

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const callback = query.get('callback');
    const idToken = (query.get('token'));
    
    useEffect(() => {
        if(callback && idToken ){
            setRegistroLink(`/registro?callback=${callback}&token=${idToken}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function hendleLogin(e){
        e.preventDefault();
        context.login(email,password).then((res)=>{
            if(res === "sucess"){
                redirect()
            }else{
                setError(res);
            }
        }).catch((error)=>{
            setError(error);
        })
    }
    function redirect(){

        if(callback === 'true'){
            return window.location = `/checkout?token=${idToken}`;
        }else{
            return window.location = '/painel/index';
        }
    }
    return(
        <>
        <div class="mfp-bg">
            <div className="login-page-pers">
                <div className="form-pers">
                    <div className="logo text-center">
                        <a href="/">
                            <img src={logo} alt=""/>
                        </a>
                    </div>
                    {error && <p style={{color: '#dc3545', fontSize:18}}>{error}</p>}
                    <form onSubmit={hendleLogin} className="login-form-pers">
                        <input type="email"  onChange={e => setEmial(e.target.value)} placeholder={t('seuEmail')} autoComplete="username"></input>
                        <input type="password"  onChange={e => setPassword(e.target.value)} placeholder={t('senha')} autoComplete="current-password"></input>
                        <button>{t('entrar')}</button>
                        <p className="doen_have_acc">{t('naoTemConta')} <a href={registroLink}>{t('registreSe')}</a></p>
                        <p className="doen_have_acc"><a href="https://personatest.com.br/recuperar-senha">{t('esqueciSenha')}</a></p>
                    </form>
                </div>
            </div>
        </div>
        </>
    );
}