import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap';
import PropTypes from 'prop-types'

export default function CardPlanos(props){
  const { t, i18n } = useTranslation();
  const [ lista, setLista ] = useState([])

  useEffect(() => {
    splitLi(props.descricao)
  },[props.descricao])

  function splitLi(string){
    let res = string.split('%%,');
    console.log(res)
    setLista(res)
  }

  return(
    <>
    {props.enable !== true ? <></>:
    <>
      {props.title === 'Free' ? <></> : 
      <div className="col-xl-4 col-md-6 col-lg-4">
          <div className="single_prising">
              <div className="prising_header deep">
                  <h3>{t(`${props.title}`)}</h3>
              </div>
              <div className="middle_content">
                  <div className="list">
                    <ul>
                      {lista.map(item => (
                        <li>{t(item)}</li>
                      ))}
                    </ul>
                    <ul>
                      {props.quantidadeMinima && <p>{t('Quantidade Mínima ')}<span>{props.quantidadeMinima}</span></p>}
                    </ul>
                  </div>
                  {props.valorAnterior && <p style={{marginBottom:0}} className="prise">{t('De ')}<span>R$ {props.valorAnterior}</span></p>}
                  <p style={{marginTop:0}}  className="prise">{t('Por ')}<span>R$ {props.valor}</span></p>
                  <div className="start_btn text-center">
                  
                  <Button onClick={() => props.openCheckout()} color="primary" style={{paddingLeft: 60, paddingRight: 60}} size="lg">{t('saibaMais')}</Button>{' '}
                  
                  </div>
              </div>
          </div>
      </div>}
    </>
    }
    </>
  )
}

CardPlanos.propTypes = {
  array: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  descricao: PropTypes.string.isRequired,
  valor: PropTypes.number.isRequired,
  valorAnterior: PropTypes.number,
  quantidadeMinima: PropTypes.number,
  enable: PropTypes.bool.isRequired,
  openCheckout: PropTypes.func.isRequired
}