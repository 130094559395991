import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import Lottie from 'react-lottie';
import * as animation from '../../assets/lottie/3152-star-success.json'

export default function Sucesso({history}){
  const { t, i18n } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    eventListeners: [
      {
        eventName: 'complete',
        callback: () => console.log('the animation completed:'),
      },
    ]
  };
  
  return(
    <>
      <Header/>
      <div className="bradcam_area breadcam_bg overlay2">
          <h3>Parabéns</h3>
      </div>
      <div class="about_area" style={{paddingTop: 100, backgroundColor: "#fff"}}>
        <div class="container">
          <div class="row mb-20">
            <div class="col-xl-12">
              
              <p>
              {t('eAgora')}
              </p>

              <p>
              {t('sucessoP1')}
              </p>

              <p>
              {t('sucessoP2')}
              </p>

              <p>
              {t('sucessoP3')}
              </p>

              <p>
              {t('sucessoP4')}
              </p>
              <a href="https://www.personatest.com.br/registro" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                      <i className="fa fa-globe" style={{fontSize: 20}}> {t('registreSe')}</i>
                  </a><br></br>

              <div className="my-3">
                <p>
                {t('nossosCanais')}<br></br>
                </p>
              </div>  
              
              <div>
                  <a href="https://www.facebook.com/personatest.oficial/" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                      <i className="fa fa-facebook-square" style={{fontSize: 20}}> Facebook</i>
                  </a><br></br>
                  <a href="https://www.youtube.com/personatest.oficial" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                      <i className="fa fa-youtube" style={{fontSize: 20}}> Youtube</i>
                  </a><br></br>
                  <a href="https://www.instagram.com/personatest.oficial/" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                      <i className="fa fa-instagram" style={{fontSize: 20}}> Instagram</i>
                  </a><br></br>
                  <a href="https://t.me/joinchat/TVhxcE2fnakxp4SDuGWUjQ" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                      <i className="fa fa-telegram" style={{fontSize: 20}}> Telegram</i>
                  </a><br></br>
                  
              </div>
              <div className="my-3">
                <p>
                {t('sucessoP5')}
                </p>
                <p class="sample-text" >
                {t('sucessoP6')} <a href='/painel' style={{color: "blue"}}>{t('aqui')}</a>
                </p>
                <p>
                {t('sucessoP7')} 
                </p>

                <p>
                {t('sucessoP8')} 
                </p>

                <p>
                {t('sucessoP9')} 
                </p>
              </div>
              
                <Lottie options={defaultOptions}
                  isStopped={false}
                  isPaused={false}
                  height={400}
                  width={400}
                  
                />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
  </>
  );
}