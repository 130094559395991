/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useEffect} from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import Context from '../../../utils/context'

export default function AdminNavba(props){
  const context = useContext(Context)
  const { t, i18n } = useTranslation();
  useEffect(()=>{

  },[])
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {t(props.brandText)}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Pesquisar" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("../../assets/img/theme/user_profiler.jpg")}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {context.getUser.usuario.nome}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{t('bemVindo')}</h6>
                  </DropdownItem>
                  <DropdownItem to="/painel/perfil" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>{t('perfil')}</span>
                  </DropdownItem>
                  <DropdownItem to="/painel/meu-resultado" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>{t('meuResultado')}</span>
                  </DropdownItem>
                  <DropdownItem to="/painel/relatorios" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>{t('relatorio')}</span>
                  </DropdownItem>
                  <DropdownItem to="/painel/pap" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>{t('pap')}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/logout" >
                    <i className="ni ni-user-run" />
                    <span>{t('sair')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
}

