const es = {
  tituloprinfipal: "Instrumento de mapeo de perfiles de comportamiento a través de la identificación del TIPO PSICOLÓGICO",
  subtituloprinfipal: "TIPO PSICOLÓGICO",
  iniciarTeste: "INICIAR PRUEBA",
  leiaMaisAqui: "Leer más aquí",
  testesRealizados: "Pruebas tomadas",

  //Header
  inicio: "comienzo",
  fazeroteste: "Hacer la prueba",
  depoimentos: "Depositions",
  verDepoimentos: "Ver testimonios",
  deixarUmDepoimento: "Deja un testimonio",
  planos: "Planes",
  sobreNos: "Sobre nosotros",
  contato: "Contacto",
  sair: "Cerrar sesión",
  entrar: "Iniciar sesión",
  painel: "Panel",
  registreSe: "Registrarse",

  //Fooder
  navegacao: 'Navegación',
  ajuda: "Ayuda",
  todosOsDireitosReservados: 'Todos los derechos reservados',
  feitoComBastante: 'Hecho con suficiente',
  por: 'por',

  //Login
  senha: "contraseña",
  naoTemConta: "¿No tienes una cuenta?",
  esqueciSenha: "Olvide mi contraseña",

  //Registre-se
  criar: "crear",
  jaTemConta: "¿Ya tienes un registro?",

  //Pagina - Iniciar Teste
  vamosComecarSeuTeste: "¿Comenzamos tu prueba?",
  digiteSeuEmail: "Ingrese su correo electrónico para comenzar",
  iniciar: "comienzo",
  gostouDoResultado: "¿Te gustó el resultado?",

   // Quiz
  seuTipoPsic: " Tu tipo psicológico es",
  querSaberMais: "¿Quieres saber más? Haz clic ",
  eSaibaTudo: " y saber todo lo que Persona Test tiene que decir sobre ti",
  percentual:"Porcentaje",

  //Pagina - Depoimentos
  data: "Fecha",
  seuEmail: "Ingrese su testimonio",
  enviandoDepoimento: "Envío de testimonios. Por favor espera...",
  depoimentoEnviado: "¡Gracias por enviar tu testimonio!",
  erroDepoimento: 'Lo sentimos, hubo un error al enviar tu testimonio',
  tenteNovamente: "Inténtalo de nuevo",

  //Modal
  estaBem:  "Está bien",

   //Checkout - index
   checkout: "Checkout",
   tokenInvalido: "Token no valido. Inténtalo de nuevo.",
   cupomInvalido: 'Código de cupón no válido o expirado!',
   cupomAtivado: 'Cupón activado exitosamente!',
   digiteUmCupom: "Por favor, introduzca un código de cupón!",
   cupomJaAtivado: "Ya has activado este cupón!",
   cupomDesconto: "Cupón de descuento",
   irParaPagina: "Ir a la página",
   digiteSeuCupom: "Ingrese el código aquí",
   plano: "Plan",
   quantidade: "Cantidad",
   valor: "Valor",
   resumo: "Resumen",
   subtotal: "Total parcial",
   desconto: "Descuento",
   total: "Total",
   concluirPedido: "Orden completa",
 
   //Checkout - sucesso
   eAgora: "¿Y ahora?",
   sucessoP1: "Ahora que conoce su TIPO PSICOLÓGICO, ha recibido un resumen explicativo de las tendencias de comportamiento inherentes a su forma de sentir, actuar y expresarse, ¡PERSONATEST quiere invitarlo a continuar profundizando su viaje de autoconocimiento!",
   sucessoP2: "Estamos preparando otros planes con contenido increíble para que pueda vivir una experiencia aún más significativa en la búsqueda de crecimiento personal y profesional.",
   sucessoP3: "Es importante que siga nuestras comunicaciones por correo electrónico, WhatsApp, Telegram y redes sociales para no perderse ninguna noticia.",
   sucessoP4: "Únase a la Comunidad Persona Test en Telegram y tenga acceso a información numerosa y exclusiva. Haga preguntas, participe con el profesor Luiz Hosannah en discusiones, tutorías grupales y mucho más. Exclusivo para quienes se registran en el sitio:",
   sucessoP5: "Siga el lanzamiento de sus informes en nuestro portal, tan pronto como la plataforma de pago confirme, los informes estarán disponibles para descargar",
   sucessoP6: "Ahora puede acceder al portal haciendo clic en",
   sucessoP7: "¡Ah, recuerde dejar su comentario en los canales para que más personas tengan la oportunidad de conocernos, conocerse y trabajar con nosotros para un mundo cada vez mejor!",
   sucessoP8: "Hombre de traje y corbata",
   sucessoP9: "Descripción generada automáticamente. ¡Buena jornada!",
   aqui: "aqui",
   nossosCanais: "Suscríbase a nuestros canales:",
 
   //Checkout - pendente
   pendente: "Pendiente",
   pendenteP1: "Su compra está en estado pendiente. Pero no se preocupe, pronto podrá usar su plan.",
 
   //Checkout - falha
   falha: "Algo salió mal",
   falhaP1: 'Lo sentimos, no fue posible finalizar su compra. Por favor intente nuevamente más tarde o contáctenos.',

   //Página 404
  erro404: "404 Pagina no encontrada",
  erro404P1: "Esto es solo un revés involuntario",
  erro404P2: " Pero sabemos que esta no es la página que está buscando y le pedimos disculpas por ello.",
  irPaginaInicial: "IR A LA PÁGINA DE INICIO",

  //Página - Contato 
  entreEmContato: "Contacto",
  enviar: "Enviar",
  digiteAssunto: "Ingresar asunto",
  digiteSeuNome: "Escriba su nombre",
  inserirMensagem: "Escribe tu mensaje",
  rua: "Rua Senador Teotônio Vilela N. 110 - Sala 509 Ed. Cidadela Center II - Bairro Parque Bela Vista",
  estado: "Salvador - Bahia, CEP - 40279435",
  telefone: "+55 21 97340-8963",
  horarios: "de lunes a viernes",
  emailContato: "contato@personatest.com.br",
  msgContato: "Envíenos un correo electrónico en cualquier momento!",

  


  // Página - Planos
  carregando: "Cargando...",
  otimaEscolha: "Buena elección",
  paraContinuar: "Para continuar, necesitamos que inicie sesión o cree uno nuevo si no tiene una cuenta.",
  
  fazerLogin: "Iniciar sesión",
  cancela: "Cancelar",
  gratis: "Gratis",
  comprar: "Compra",
  saibaMais: "Sepa mas",
  cadastreSe: "Registrarse",

  "Testes ilimitados":"Pruebas ilimitadas.",
  "Resultado na tela":"Resultado en pantalla",
  "Compartilhamento nas redes sociais":"Compartir en redes sociales.",
  "Relatório segmentado por área em pdf":"Informe segmentado en PDF",
  "e-book em pdf":"libro electrónico pdf",
  "PAP":"PAP",
  "Plano de Aprimoramento Pessoal em pdf":"Plan de mejora personal en pdf",
  "Livro Tipos Psicológicos (digital)↵":"Libro de Tipos Psicológicos (digital)",

  "Compartilhamento nas redes sociais.":"TESTE",
  

  
  // Página - Sobre-nós
  p1: "¿Te has dado cuenta de lo difícil que es tomar decisiones frente a tantas alternativas que ofrece la vida, ya sea en el amoroso, profesional, educativo, promoción de la salud o la relación entre amigos y familiares? ¿Tiene dificultades para comprender quién es usted, es más tolerante con sus límites y confía en sus posibilidades?",
  p2: "PERSONA TEST es una herramienta de mapeo de perfiles de comportamiento basada en la teoría de CG JUNG sobre TIPOS PSICOLÓGICOS y ayudará a superar estos obstáculos, tanto desde el punto de vista individual como colectivo (empresas, academias, escuelas, clínicas, etc.).",
  p3: "Según C. G. Jung, hay cuatro tipos de perfil de comportamiento: PENSAMIENTO, SENTIMIENTO, SENSACIÓN e INTUICIÓN. Todas las personas tienen características de los cuatro, no hay mejor ni peor, lo ideal es el equilibrio entre ellas y cada persona tiene un tipo superior que determina su tendencia conductual.",
  p4: "La aplicación de los resultados de la PERSONATEST es integral y capaz de contribuir al desarrollo humano a nivel individual y colectivo. En términos individuales, permite el autoconocimiento y la identificación de las principales competencias cognitivas, emocionales y conductuales que predominan en la persona. Esta es una herramienta estratégica esencial para mapear potencialidades, reconocer limitaciones, tolerar diferencias y aumentar el aprendizaje de nuevos roles que permitan la construcción de una vida plena basada en la autenticidad, la libertad, la autonomía y el propósito.",
  p5: "En términos organizativos, es una herramienta única, capaz de proporcionar subsidios a los gerentes para promover la seguridad laboral, la efectividad en el reclutamiento y la selección, optimizar el proceso de ventas, involucrar a los equipos y capacitar a los líderes. También permite que una institución educativa o gimnasio pueda mapear el perfil de los estudiantes y capacitar a los educadores para usar técnicas pedagógicas y otros enfoques compatibles con la tipología individual o colectiva, estimulando el aprendizaje, la lealtad y el rendimiento.",
  p6: "PERSONATEST es el resultado de más de treinta años de trabajo e investigación del profesor Luiz Hosannah en el área de desarrollo y comportamiento humano y mapea el perfil tipológico de los que responden la prueba, explicando la proporción de cada uno de los cuatro tipos en la composición de su personalidad",
  p7: "Fácil de entender, simple de hablar y rápido de ejecutar, las personas mayores de quince años podrán identificar su perfil y beneficiarse de esta increíble herramienta de autoconciencia. El informe final es una excelente oportunidad para el autoconocimiento y la autotransformación, basado en la identificación del perfil tipológico del comportamiento.",
  p8: "Precisamente debido a las innumerables posibilidades de aplicar resultados PERSONATEST, puede elegir entre tres opciones de informes:",
  p9: "En términos organizativos, es una herramienta única, capaz de proporcionar subsidios a los gerentes para promover la seguridad laboral, la efectividad en el reclutamiento y la selección, optimizar el proceso de ventas, involucrar a los equipos y capacitar a los líderes. También permite que una institución educativa o gimnasio pueda mapear el perfil de los estudiantes y capacitar a los educadores para usar técnicas pedagógicas y otros enfoques compatibles con la tipología individual o colectiva, estimulando el aprendizaje, la lealtad y el rendimiento.",
  
  p10: "en este modelo conocerá su TIPO PSICOLÓGICO y recibirá un resumen explicativo de las tendencias de comportamiento inherentes a su forma de sentir, actuar y expresarse. ¡Es un buen comienzo!",
 
  p11: "Sin embargo, si reconoce que es necesario ir un paso más allá y su exigencia es profundizar la comprensión de su estructura psicodinámica, entonces una opción excelente es el plan más avanzado:",

  p12: "Además del contenido del modelo GRATUITO, tendrá acceso a un informe descriptivo mucho más avanzado que mapea la forma en que se relaciona con las diferentes dimensiones de su vida diaria en el ejercicio de múltiples personas:",
  p13: "¿Cómo aprendes y te comunicas?",
  p14: "¿Cuál es su estilo de liderazgo y cómo contribuye a los procesos grupales?;",
  p15: "¿Cómo te motivas ante los desafíos?",
  p16: "Qué posiciones y funciones se relacionan mejor con su tipología;",
  p17: "Pasos iniciales para equilibrar su tipología;",
  p18: "Tareas organizativas que pueden generar más riesgos de fallas operacionales, no conformidades y accidentes en el trabajo;",
  p19: "Su perfil de consumidor;",
  p20: "Sus puntos débiles y sus necesidades de mejora en términos de comportamiento;",
  p21: "¿Cómo intentas resolver problemas en la vida cotidiana?",
  p22: "¿Cómo es tu comportamiento en las relaciones amorosas?",
  p23: "¿Cómo manejas la educación de tus hijos?",

  p24: "El otro modelo es mucho más global y completo, con la misión de instrumentar su viaje de auto-transformación.",

  p25: "Este modelo ofrece todo el contenido de los planes anteriores e incluye el PAP - PLAN DE MEJORA PERSONAL Y PROFESIONAL. Este plan de acción personalizado tiene como objetivo instrumentalizarlo para permitir el equilibrio deseado entre los 4 TIPOS PSICOLÓGICOS. Es un guión con ejercicios y un paso a paso detallado y autoexplicativo, que contribuirá a un salto cualitativo en la construcción de su propósito e integración entre PERSONA y SOMBRA.",

  p26: "El profesor Hosannah se graduó en 1988 de la UFBa, tiene una maestría en Historia de la UFBa, especialista en educación, tiene experiencia en Psicoanálisis y Psicología Analítica Jungiana, un postgrado en Psicología Organizacional y Psicología Positiva, fue profesor universitario durante más de dos años. décadas y actualmente enseña cursos de posgrado en Brasil y en el extranjero.",
  p27: "Psicoterapeuta por más de 30 años y carismático orador nacional, es autor de varios artículos publicados y consultor de varias organizaciones del tamaño de PETROBRÁS, BRASKEM, CORREIOS, FERBASA, TELEBRÁS, CHESF, PERINI, OAS, HOSPITAL SÃO RAFAEL, BAHIAGÁS, JUCEB, SEBRAE , SESI, FAFEN, CAIXA ECONÔMICA y cientos de otros en todo Brasil.",
  p28: "Viajero, visitó casi 100 países y creó el proyecto VIAJANDO CON EL PROFESOR HOSANNAH en el canal youtube.com/luizhosannah. En este proyecto, graba videos frente a monumentos históricos y simbólicos de todo el mundo y correlaciona la historia, el arte, la mitología y la diversidad cultural con el desarrollo humano, la inteligencia emocional y los arquetipos.",
  p29: "Venga y benefíciese de esta herramienta de tecnología de punta, ejecución rápida, alta confiabilidad y basada en conocimiento científicamente validado.",
  p30: "Buen viaje hacia el autoconocimiento y la mejor versión de ti mismo en una vida plena y decidida.",

  p31: "Mis contactos",

  p32: "Pruebas realizadas hasta ahora",
  p33: "¿Tengo una pregunta?",

    // Traduçoes para o Dashboard

  // Header
  meuResultado: "Mi resultado",
  meusRelatorios: "Meus Relatórios",

  //Youtube
  canalYoutube: "Canal do YouTube ",
  novosVideos: "Nuevos vídeos",
  //Meus Arquivos
  meusArquivos: "Mis archivos",
  LIVRO_JUNG_BEM_ESTAR_CONFIABILIDADE_DIGITAL: "RESERVAR JUNG BIEN SER CONFIABILIDAD",
  TIPOS_PSICOLÓGICOS: "TIPOS PSICOLÓGICOS",
  papEmPdf: "Plan de mejora personal",
  baixarLivro: "Descargar libro",
  baixarEbook: "Descargar E-book",

  facaParteComnunidadeTelegram: "Únete a la comunidad de Persona Test en Telegram",
  tenhaAcesso: "y tener acceso a información incontable y exclusiva. Haga preguntas, participe en discusiones grupales y tutoría y mucho más, con el profesor Luiz Hosannah.",
  pagamentoPendete: "Su pago se está procesando y una vez que se confirme su informe, estará disponible aquí ...",
  baixarPDF: "Descargue su PDF",
  baixarPAP: 'Descargar PAP',
  anterior: "Anterior",
  proximo: "Próximo",
  
  tipoPsicologico: "Tipo psicologico",
  compartilhar: "Para compartir",
  erroAoSalvar: "Hubo un error al guardar. Por favor, inténtelo de nuevo más tarde.",
  qntTestesRealizados: "Pruebas",
  minhaConta: "Mi cuenta",
  informacaoUsuario: "Informacion del usuario",
  nomeUsuario: "Nombre de usuario",
  emailUsuario: "Dirección de correo electrónico",
  nascimentoUsuario: "Fecha de nacimiento",
  contatoUsuario: "Información del contacto",
  telefoneUsuario: "Teléfono",
  numeroTelefone: "Número de teléfono",
  cidadeUsuario: "Ciudad",
  seuCidade: "Su ciudad",
  estadoUsuario: "Estato",
  selecionarEstado: "Seleccione",
  paisUsuario: "República",
  seuPais: "Su república",
  alterarSenha: "Cambiar contraseña",
  novaSenha: "Nueva contraseña",
  digiteNovaSenha: "Ingrese nueva clave",
  cadastroAtualizado: "Registro actualizado",

  perfil: "Mi perfil",
  relatorio: "Informes",
  pap: "PAP",
  Dashboard: "Dashboard",
  email: "Email",
  mensagens: "publicaciones",
  bemVindo: "Bienvenido!"

}
export default es;
