import React,{useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next';
import logo from    '../../assets/img/logo.png';
import { useLocation } from 'react-router-dom'
import { PessoaFisica, PessoaJuritica } from "../../components/Registrar/index";
import { registre, login } from '../../services/auth'
import "./App.scss";
//import './styles.css'
/*
export default function Registro(){
    const { t, i18n } = useTranslation();
    const [ username, setUsername] = useState('');
    const [ password, setPassword ] = useState('');
	const [ error, setError ] = useState('');

	const [ loginLink, setLoginLink ] = useState('/login');

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
	let query = useQuery();
	
	const callback = query.get('callback');
    const idToken = (query.get('token'));
	
	useEffect(() => {
        if(callback && idToken ){
            setLoginLink(`/login?callback=${callback}&token=${idToken}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function hendleRegistro(e){
        e.preventDefault();
        registre(username, email, password).then(() =>{
            login(email, password).then(() => {
                redirect()
            }).catch(e => {
                setError(e)
            })
        }).catch((e)=>{
            setError(e)
        })
	}
	
    function redirect(){

        if(callback === 'true'){
            return window.location = `/checkout?token=${idToken}`;
        }else{
            return window.location = '/painel/index';
        }
    }

    componentDidMount() {
      //Add .right by default
      this.rightSide.classList.add("right");
    }
    return(
       /* <>
        <div className="mfp-bg">
            <div className="register-form-pers">
                <div className="form-pers">
                    <div className="logo text-center">
                        <a href="/">
                            <img src={logo} alt=""/>
                        </a>
                    </div>
                    {error && <p style={{color: '#dc3545', fontSize:18}}>{error}</p>}
                    <form onSubmit={hendleRegistro} className="login-form-pers">
                        <input type="username"  onChange={e => setUsername(e.target.value)} placeholder={t('digiteSeuNome')} autoComplete="username"></input>
                        <input type="email"  onChange={e => setEmial(e.target.value)} placeholder={t('seuEmail')} autoComplete="username"></input>
                        <input type="password"  onChange={e => setPassword(e.target.value)} placeholder={t('senha')} autoComplete="current-password"></input>
                        <button>{t('criar')}</button>
                        <p className="doen_have_acc">{t('jaTemConta')} <a href={loginLink}>{t('fazerLogin')}</a></p>
                    </form>
                </div>
            </div>
        </div>
        </>
       <Register/>
    );
}*/

class Registro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogginActive: true
    };
  }

  componentDidMount() {
    //Add .right by default
    this.rightSide.classList.add("right");
  }

  changeState() {
    const { isLogginActive } = this.state;

    if (isLogginActive) {
      this.rightSide.classList.remove("right");
      this.rightSide.classList.add("left");
    } else {
      this.rightSide.classList.remove("left");
      this.rightSide.classList.add("right");
    }
    this.setState(prevState => ({ isLogginActive: !prevState.isLogginActive }));
  }

  render() {
    const { isLogginActive } = this.state;
    const current = isLogginActive ? "Pessoa Jurídica" : "Pessoa Física";
    const currentActive = isLogginActive ? "Pessoa Física" : "Pessoa Jurídica";
    return (
      <div className="App">
        <div className="login">
          <div className="container" ref={ref => (this.container = ref)}>
            {isLogginActive && (
              <PessoaFisica containerRef={ref => (this.current = ref)} />
            )}
            {!isLogginActive && (
              <PessoaJuritica containerRef={ref => (this.current = ref)} />
            )}
          </div>
          <RightSide
            current={current}
            currentActive={currentActive}
            containerRef={ref => (this.rightSide = ref)}
            onClick={this.changeState.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const RightSide = props => {
  return (
    <div
      className="right-side"
      ref={props.containerRef}
      onClick={props.onClick}
    >
      <div className="inner-container">
        <div className="text">{props.current}</div>
      </div>
    </div>
  );
};

export default Registro;