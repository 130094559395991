import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api'
import { format } from 'date-fns'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import ModalLoading from '../../components/Modals/ModalLoading'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'


export default function EnviarDepoimento({history}){
  const { t, i18n } = useTranslation();
  const [ nome, setNome ] = useState('');
  const [ texto, setTexto ] = useState('')
  const [modalLodaing, setModalLoading] = useState(false);
  const [modalSucesso, setModalSucesso] = useState(false);
  const [modalError, setModalError] = useState(false);
  
  function toggleSucesso(){
    console.log('Sucesso');
    setModalSucesso(!modalSucesso);
  }
  function toggleError(){ 
    setModalError(!modalError);
  }

  async function handleSubmit(e){
    e.preventDefault();
    if(nome && texto){
      setModalLoading(true)
      const newDate = getDataFormat();
      const data = {
        _by: "",
        publicado: false,
        nome,
        texto,
        data: newDate
      }
      
      await api.post('cms/collections/save/comentarios', data)
      .then(res => {
        console.log(res.data)
        setModalLoading(false)
        setNome('');
        setTexto('');
        toggleSucesso()
        
      })
      .catch(err => {
        console.log(err.response)
        setModalLoading(false)
        toggleError()
      })
    }
  //  
    
  }

  function getDataFormat(){
    return format(new Date(), 'dd/MM/yyyy HH:mm:ss')
  }

  return(
    <>
      <Header/>
      <div className="bradcam_area breadcam_bg_4_tipos" style={{
          marginTop: 80, 
          backgroundSize: "contain"
          }}>
      </div>
        <section className="contact-section" style={{ background: "#FFF"}}>
          <div className="container">
              <div className="col-xl-12">
                  <h1 className="contact-title" style={{textAlign: "center", marginBottom: 60,  fontWeight: 600, }} >{t('deixarUmDepoimento')}</h1>
              </div>
              <div className="row d-flex justify-content-center">
                  {/* <div className="col-12">
                      <h2 className="contact-title" >Deixe seu depoimento</h2>
                  </div> */}
                  <div className="col-lg-6">
                      <form className="form-contact contact_form" onSubmit={handleSubmit}  id="contactForm" novalidate="novalidate">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div className="form-group">
                                      <input 
                                      className="form-control valid" 
                                      value={nome}
                                      onChange={e => setNome(e.target.value)}
                                      name="name" type="text" 
                                      onfocus="this.placeholder = ''" 
                                      onblur="this.placeholder = 'Digite seu nome'" 
                                      placeholder={t('digiteSeuNome')} 
                                      autoComplete="username"></input>
                                  </div>
                              </div>
                              <div className="col-12">
                                  <div className="form-group">
                                      <textarea 
                                      className="form-control w-100" 
                                      value={texto}
                                      onChange={e => setTexto(e.target.value)}
                                      name="message" id="message" 
                                      cols="30" rows="9" 
                                      onfocus="this.placeholder = ''" 
                                      onblur="this.placeholder = 'Digite seu depoimento...'" 
                                      placeholder={t('Digite seu depoimento...')}>
                                      </textarea>
                                  </div>
                              </div>
                          </div>
                          <div className="form-group mt-3">
                              <button type="submit" className="button button-contactForm boxed-btn">{t('enviar')}</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
        </section> 
        <ModalLoading
        isOpen={modalLodaing} 
        title={t('enviandoDepoimento')}>
        </ModalLoading>
        <Modal isOpen={modalSucesso} toggle={toggleSucesso}>
          <ModalBody>
            {t('depoimentoEnviado')}
          </ModalBody>
          <ModalFooter>
          <Button style={{backgroundColor: "#670080", color: "#fff"}} onClick={toggleSucesso}>{t('estaBem')}</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modalError} toggle={toggleError}>
          <ModalBody>
            {t('erroDepoimento')}<br></br>
          </ModalBody>
          <ModalFooter>
          <Button style={{backgroundColor: "#670080", color: "#fff"}} onClick={toggleError}>{t('tenteNovamente')}</Button>
          </ModalFooter>
        </Modal>
      <Footer/>
  </>
  );
}