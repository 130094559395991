import React, {useEffect, useContext} from 'react';
import Context from '../../utils/context'

export default function Logout({history}){
    const context = useContext(Context);
    useEffect(()=>{
        if(context.isAuthenticated()){
            context.logout().then((res)=>{
                return window.location = '/';
            }).catch((error)=>{
                console.log(error);
                return window.location = '/';
            });
        }
    },[]);

    return(
        <>
            <h1>Saindo...</h1>
        </>
    );
}