import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import api  from '../../../services/api'
import axios from 'axios'
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, LinkedinShareButton,  EmailShareButton  } from 'react-share'
import {  FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LinkedinIcon, EmailIcon } from 'react-share'
import HeaderResultado from "./HeaderResultado";
import { Card, CardBody, Button, Container, CardColumns, Row, Col } from "reactstrap";
import Context from "../../../utils/context";
import { parseArrayMapResDet, createNewData } from '../../../utils/Charts/ChatsFunctionUtils'
import ResultadoCharts from '../../../components/Charts/utils/ResultadoCharts';
import CardDepoimento from '../../../components/Cards/CardDepoimento'

export default function MeuResultado(){
  const context = useContext(Context);
  const { t, i18n } = useTranslation();
  const [ result, setResult ] = useState('Carregando...');
  const [ titulo, setTitulo ] = useState('Carregando..');
  const [ datasete, setDatasete ] = useState({});
  const [ completed, setCompleted ] = useState(false);
  const [ hasPlan, setHasPlan ] = useState(false)
  const [ textSmall, setTextSmall ] = useState('')
  const [ depoimentos, setDepoimentos ] = useState(null)
  const [ loading, setLoading ] = useState(true)


    useEffect(()=>{
      checkPlan(context.getUser.usuario.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function checkPlan(email){

      await api.post('/purchase/active', {email}).then(res => {
          const { Produto } = res.data[0];
          console.log(res.data)
          if(Produto){
            console.log('Tem plano')
            setHasPlan(true)
          }
          resultado(email)
          getComentarios()
      }).catch(err => {
          console.error(err)
      })
  }
  async function getComentarios(){
    await axios.post(`https://cms.personatest.com.br/api/collections/get/comentarios?token=e1fca3527f0615d1a4c66017ebb84e`,
    {
      filter: {
        publicado: true
      },
      fields: {
          publicado: 1, nome: 1, texto: 1, data: 1
        },
      sort: {
        _created: -1
      },
      limit: 6
    })
    .then(res => {
        const { entries } = res.data
        console.log(entries)
        setDepoimentos(entries)
    }).catch(err => {
        console.log(err)
    })
  }

  function getIdioma(){
    const lng = i18n.languages[0];
    if(lng === 'en') return 2
    else if (lng === 'es') return 4
    else return 1
}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function resultado(email){
      console.log('Tenha um ótimo dia 👏🏻🙌🏻',email)
      await api.get(`funtions/eixos?email=${email}`).then((response)=>{
          const { idTipoSuperior, idTipoAuxiliar } = response.data[0];
          console.log('Resultado: ',idTipoSuperior, idTipoAuxiliar)
          getEixosRespastas(idTipoSuperior,idTipoAuxiliar);
      }).catch((error)=>{
          console.log(error);
      })
  }
    async function getEixosRespastas(s,a){
      let idioma = getIdioma()
      console.log('idioma', idioma)
        await api.get(`funtions/resumoeixo?idIdioma=1&idSuperior=${s}&idAuxiliar=${a}&origem=N`).then((res)=>{
            console.log('eixos: ',res.data);
            const { data } = res;
            setTitulo(data.Eixo);
           
            const text = data.descricao.substring(0, 150);
            setTextSmall(text)   
            setResult(data.descricao);

            resultadoDetalhado(context.getUser.usuario.email) 
        }).catch((error)=>{
            console.log(error.message);
            console.log(error.response);
        })
    }
    async function resultadoDetalhado(email){
      await api.get(`funtions/respostas?email=${email}&detalhe=true`).then((res)=>{
          console.log(res.data)
          const _data = parseArrayMapResDet(res.data);
          console.log(_data)
          const _vq = createNewData(_data.tipo,_data.qttipo);
          const _vp = createNewData(_data.tipo,_data.percentual);
          const new_data = [_vq,_vp]
          console.log(new_data)
          setDatasete(new_data);
          setCompleted(true)
      }).catch((error)=>{
          console.log(error);
      });
  }
    return(
        <>
            <HeaderResultado eixo={titulo} />
            <Container className="mt--7" fluid>
                <Row>
                <Col lg="12" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                        <Row>
                          <div className="col">
                            <p className="mt-3 mb-0 text-muted text-sm">
                              <span className="text-nowrap">{t('compartilhar')} </span>
                            </p>
                          </div>
                        </Row>
                        <Row>
                          <div className="col mb-4">
                            <FacebookShareButton url="https://personatest.com.br/inicia-teste" hashtag="#tipopsicologico" quote={`${titulo} é o meu Tipo Psicológico. Descubra o seu clicando no link abaixo`}>
                              <FacebookIcon size={40} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton url="https://personatest.com.br/inicia-teste" title={`${titulo} é o meu Tipo Psicológico. Descubra o seu clicando no link abaixo`} via="vsetecnologia" hashtags={["personatest", "meutipopsicologico"]}>
                              <TwitterIcon size={40} round={true}/>
                            </TwitterShareButton>
                            <TelegramShareButton url="https://personatest.com.br/inicia-teste" title={`${titulo} é o meu Tipo Psicológico. Descubra o seu clicando no link abaixo`}>
                              <TelegramIcon size={40} round={true}/>
                            </TelegramShareButton>
                            <WhatsappShareButton url={`${titulo} é o meu Tipo Psicológico. Descubra o seu em https://personatest.com.br/inicia-teste`}>
                              <WhatsappIcon size={40} round={true}/>
                            </WhatsappShareButton>
                            <LinkedinShareButton url="https://personatest.com.br/inicia-teste" source="http://personatest.com.br/inicia-teste" title="INTUIÇÃO e PERNSAMENTO é o meu Tipo Psicológico." summary="Descubra o seu clicando no link abaixo">
                              <LinkedinIcon size={40} round={true}/>
                            </LinkedinShareButton>
                            <EmailShareButton url="https://personatest.com.br/inicia-teste" subject={titulo} body={`${titulo} é o meu Tipo Psicológico. Descubra o seu em https://personatest.com.br/inicia-teste`} separator=" ">
                              <EmailIcon size={40} round={true} />
                            </EmailShareButton>
                            </div>
                        </Row>
                      <Row>
                        {completed ? hasPlan ? 
                        <div className="col">
                            <p  dangerouslySetInnerHTML={{__html: result}}>
                            </p>
                        </div> :
                        <div className="col">
                            <p style={{ fontSize: 22}} dangerouslySetInnerHTML={{__html: textSmall}}>
                            </p><p>...</p>
                            <p style={{ fontSize: 22}} >Quer saber mais? Clique <a href="/planos">aqui</a> e saiba tudo que o Persona Test tem a dizer sobre você</p>
                        </div> : null }
                        
                      </Row>
                      {hasPlan === false ? 
                        <div className="row">
                            <div className="col-12">
                            <h2 className="contact-title" >{t('depoimentos')}</h2>
                            </div>
                            <div className="col-12">
                            <CardColumns>
                                {depoimentos === null ? <h1>{t('carregando')}</h1> :
                                    depoimentos.map((item, key)=> (
                                    <CardDepoimento
                                    key={key} 
                                    ativo={item.publicado}
                                    nome={item.nome} 
                                    texto={item.texto}
                                    data={item.data}/>
                                    ))
                                }
                            </CardColumns>
                            </div>
                        </div> : null}
                      <Row>
                        <div style={{marginTop: 25, marginLeft: 10}}>
                            <h3>{t('gostouDoResultado')}</h3>
                            <Button color="primary">
                                <Link style={{color: "#ffffff"}} to="/novo-depoimento">{t('deixarUmDepoimento')}</Link>
                            </Button>
                        </div>
                      </Row>
                      {hasPlan && <ResultadoCharts completed={completed} datasete={datasete} />}
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">Atualizado em: </span>
                        <span className="text-success mr-2">
                           {meuResuldtadoData.dataTeste}
                        </span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                </Row>
            </Container>
        </>
    );
}