export function parseArrayMapResDet(data){
        const tipo = Object.keys(data).map(v=>data[v].Tipo);
        const qttipo = Object.keys(data).map(v=>data[v].qtdTipo);
        const percentual = Object.keys(data).map(v=>data[v].percentual);
        return { tipo, qttipo, percentual }
    }

export function createNewData(_labels, _values,_backgroundColor = ['#8dace7','#71deb9','#ef869e','#B4CEFF'], _hoverBackgroundColor = ['#7097e1','#4dd6a7','#eb6886','#B4CEFF']  ){
        const labels = _labels.map(l=>l);
        const values = _values.map(v => v);
        const backgroundColor = _backgroundColor.map(bc => bc);
        const hoverBackgroundColor = _hoverBackgroundColor.map(hb=>hb);
        const newData = {
            labels,
            datasets: [{
                data: values,
                backgroundColor,
                hoverBackgroundColor,
            }]
        };
        return newData
    }