/* eslint-disable react/style-prop-object */
import React, { useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { Button, CardColumns } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ResultadoCharts from '../Charts/utils/ResultadoCharts'
import CardDepoimento from '../Cards/CardDepoimento'
import api from '../../services/api'
import axios from 'axios'

export default function Resultado(props, {history}){
    const { t, i18n } = useTranslation();
    const [ hasPlan, setHasPlan ] = useState(false)
    const [ textSmall, setTextSmall ] = useState('')
    const [ loading, setLoading ] = useState(true)
    const [ depoimentos, setDepoimentos ] = useState(null)
    
    useEffect(() => {
        getComentarios()
        async function checkPlan(email){
            await api.post('/purchase/active', {email}).then(res => {
                const { Produto } = res.data[0];
                console.log(res.data)
                if(Produto){
                  console.log('Tem plano')
                  setHasPlan(true)
                }
                const text = props.texto.substring(0, 150);
                setTextSmall(text)   
                setLoading(true)
            }).catch(err => {
                console.error(err)
            })
        }

        checkPlan(props.email)
        console.log('Resultato')
    },[props.email, props.texto])

    async function getComentarios(){
        await axios.post(`https://cms.personatest.com.br/api/collections/get/comentarios?token=e1fca3527f0615d1a4c66017ebb84e`,
        {
          filter: {
            publicado: true
          },
          fields: {
              publicado: 1, nome: 1, texto: 1, data: 1
            },
          sort: {
            _created: -1
          },
          limit: 6
        })
        .then(res => {
            const { entries } = res.data
            console.log(entries)
            setDepoimentos(entries)
        }).catch(err => {
            console.log(err)
        })
      }
    return(
        <>
        <h2 style={{ paddingBottom: 80}}>{t('seuTipoPsic')}: {props.titulo}</h2>
        <section className="sample-text-area" style={{paddingTop: 0}}>
            <div className="container box_1170">
                {loading ? hasPlan ? 
                    <div className="col">
                        <p  dangerouslySetInnerHTML={{__html: props.texto}}>
                        </p>
                    </div> :
                    <div className="col">
                        <p style={{ fontSize: 22}} dangerouslySetInnerHTML={{__html: textSmall}}>
                        </p><p>...</p>
                        <p style={{ fontSize: 22}}>{t('querSaberMais')}<a href="/registro">{t('aqui')}</a>{t('eSaibaTudo')}</p>
                    </div> : <h6 >{t('carregando')}</h6> }
                
                <div style={{marginTop: 25}}>
                    <h3>{t('gostouDoResultado')}</h3>
                    <Button color="primary">
                        <Link style={{color: "#ffffff"}} to="/novo-depoimento">{t('deixarUmDepoimento')}</Link>
                    </Button>
                </div>
                {hasPlan === false ? 
                    <div className="row">
                        <div className="col-12">
                        <h2 className="contact-title" >{t('depoimentos')}</h2>
                        </div>
                        <div className="col-12">
                        <CardColumns>
                            {depoimentos === null ? <h1>{t('carregando')}</h1> :
                                depoimentos.map((item, key)=> (
                                <CardDepoimento
                                key={key} 
                                ativo={item.publicado}
                                nome={item.nome} 
                                texto={item.texto}
                                data={item.data}/>
                                ))
                            }
                        </CardColumns>
                        </div>
                    </div> : null}
                    {hasPlan && <ResultadoCharts className="conteiner box_1170" completed={props.completed} datasete={props.datasete} /> }
            </div>
        </section>
        </>
    );
}

Resultado.propTypes = {
    //quizResult: PropTypes.string.isRequired,
    titulo: PropTypes.string.isRequired,
    texto: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
    datasete: PropTypes.array.isRequired,
    email: PropTypes.string.isRequired,
  };