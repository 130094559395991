/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Index from "./views/Index.jsx";
// import Profile from "./views/examples/Profile.jsx";
// import Maps from "./views/examples/Maps.jsx";
// import Register from "./views/examples/Register.jsx";
// import Login from "./views/examples/Login.jsx";
// import Tables from "./views/examples/Tables.jsx";
// import Icons from "./views/examples/Icons.jsx";

import Painel from "./views/Painel";
import Perfil from "./views/Perfil";
import Planos from "./views/Planos";
import MeuResultado from "./views/MeuResultado"
import Relatorios from "./views/Relatorios"
import MeusArquivos from "./views/MeusArquivos"
// import PAP from "./views/PAP"
// import Mentorias from "./views/Mentorias"
// import Aulas from "./views/Aulas"

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-red",
    component: Painel,
    layout: "/painel"
  },
  {
    path: "/meu-resultado",
    name: "meuResultado",
    icon: "ni ni-favourite-28 text-orange",
    component: MeuResultado,
    layout: "/painel"
  },
  {
    path: "/relatorios",
    name: "relatorio",
    icon: "ni ni-chart-bar-32 text-yellow",
    component: Relatorios,
    layout: "/painel"
  },
  {
    path: "/meus-arquivos",
    name: "Meus Arquivos",
    icon: "ni ni-books text-blue",
    component: MeusArquivos,
    layout: "/painel"
  },
  // {
  //   path: "/pap",
  //   name: "PAP - Plano de Aprimoramento Pessoal e Profissional",
  //   icon: "ni ni-spaceship text-blue",
  //   component: PAP,
  //   layout: "/painel"
  // },
  // {
  //   path: "/mentoria",
  //   name: "Mentoria",
  //   icon: "ni ni-trophy text-green",
  //   component: Mentorias,
  //   layout: "/painel"
  // },
  // {
  //   path: "/video-aulas",
  //   name: "Video Aulas",
  //   icon: "ni ni-books text-red",
  //   component: Aulas,
  //   layout: "/painel"
  // },
  {
    path: "/planos",
    name: "planos",
    icon: "ni ni-credit-card text-pink",
    component: Planos,
    layout: ""
  },
  {
    path: "/perfil",
    name: "perfil",
    icon: "ni ni-single-02 text-info",
    component: Perfil,
    layout: "/painel"
  },
]

// var routes = [

//   {
//     path: "/index",
//     name: "Dashboard",
//     icon: "ni ni-tv-2 text-primary",
//     component: Index,
//     layout: "/painel"
//   },

//   {
//     path: "/icons",
//     name: "Icons",
//     icon: "ni ni-planet text-blue",
//     component: Icons,
//     layout: "/painel"
//   },
//   {
//     path: "/maps",
//     name: "Maps",
//     icon: "ni ni-pin-3 text-orange",
//     component: Maps,
//     layout: "/painel"
//   },
//   {
//     path: "/user-profile",
//     name: "Seu Perfil",
//     icon: "ni ni-single-02 text-yellow",
//     component: Perfil,
//     layout: "/painel"
//   },
//   {
//     path: "/tables",
//     name: "Tables",
//     icon: "ni ni-bullet-list-67 text-red",
//     component: Tables,
//     layout: "/painel"
//   },
//   {
//     path: "/login",
//     name: "Login",
//     icon: "ni ni-key-25 text-info",
//     component: Login,
//     layout: "/auth"
//   },
//   {
//     path: "/register",
//     name: "Register",
//     icon: "ni ni-circle-08 text-pink",
//     component: Register,
//     layout: "/auth"
//   },
  
// ];
export default routes;
