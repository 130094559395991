const pt = {
  tituloprinfipal: "Instrumento de mapeamento de perfis comportamentais através da identificação do",
  subtituloprinfipal: "TIPO PSICOLÓGICO",
  iniciarTeste: "INICIAR TESTE",
  leiaMaisAqui: "Leia mais aqui ",
  testesRealizados: "Testes realizados",

  //Header
  inicio: "Inicio",
  fazeroteste: "Fazer o teste",
  depoimentos: "Depoimentos",
  verDepoimentos: "Ver depoimentos",
  deixarUmDepoimento: "Deixar um depoimento",
  planos: "Planos",
  sobreNos: "Sobre nós",
  contato: "Contato",
  sair: "Sair",
  entrar: "Entrar",
  painel: "Painel",
  registreSe: "Registre-se",

  //Fooder
  navegacao: 'Navegação',
  ajuda: "AJuda",
  todosOsDireitosReservados: 'Todos os direitos reservados',
  feitoComBastante: 'Feito com bastante',
  por: 'por',

  //Login
  senha: "Senha",
  naoTemConta: "Não possui uma conta?",
  esqueciSenha: "Esqueci minha senha",

  //Registre-se
  criar: "criar",
  jaTemConta: "Já tem um registro?",
  

  //Pagina - Iniciar Teste
  vamosComecarSeuTeste: "Vamos começar o seu teste?",
  digiteSeuEmail: "Insira o seu email para iniciar",
  iniciar: "Iniciar",
  gostouDoResultado: "Gostou do resultado?",

  // Quiz
 seuTipoPsic: " Seu tipo psicológico é",
 querSaberMais: "Quer saber mais? Faça seu cadastro ",
 eSaibaTudo: " e saiba tudo que o Persona Test tem a dizer sobre você",
 percentual:"Percentual",

  //Pagina - Depoimentos
  data: "Data",
  seuEmail: "Digite seu email",
  enviandoDepoimento: "Enviando depoimento. Por favor, aguarde...",
  depoimentoEnviado: "Obrigado por enviar seu depoimento!",
  erroDepoimento: 'Desculpe, ocorreu um erro ao enviar seu depoimento',
  tenteNovamente: "Tente novamente",

  //Modal
  estaBem:  "Está Bem",

  //Checkout - index
  checkout: "Checkout",
  tokenInvalido: "Token invalido. Por favor tente novamente.",
  cupomInvalido: 'Código de cupom invalido ou expirado!',
  cupomAtivado: 'Cupom ativado com sucesso!',
  digiteUmCupom: "Por favor, digite um código de cupom!",
  cupomJaAtivado: "Você já ativou esse cupom!",
  cupomDesconto: "Cupom de Desconto",
  irParaPagina: "Ir para a página",
  digiteSeuCupom: "Digite o código aqui",
  plano: "Plano",
  quantidade: "Quantidade",
  valor: "Valor",
  resumo: "Resumo",
  subtotal: "Subtotal",
  desconto: "Desconto",
  total: "Total",
  concluirPedido: "Concluir Pedido",
  erroAoEnviar:"Erro ao enviar",

  //Checkout - sucesso
  eAgora: "E agora?",
  sucessoP1: "Agora que você já conhece qual é o seu TIPO PSICOLÓGICO, recebeu um resumo explicativo das tendências comportamentais inerentes à sua forma de sentir, agir e se expressar, o PERSONATEST quer convidar você a continuar se aprofundando na jornada de autoconhecimento!",
  sucessoP2: "Estamos preparando outros planos com conteúdos incríveis para que você possa viver uma experiência ainda mais significativa pela busca do crescimento pessoal e profissional.",
  sucessoP3: "É importante que você siga nossas comunicações por e-mail, WhatsApp, Telegram e redes sociais para não perder nenhuma novidade.",
  sucessoP4: "Faça parte da Comunidade Persona Test no Telegram e tenha acesso a inúmeras e exclusivas informações. Tire dúvidas, participe com o Professor Luiz Hosannah de discussões, mentoria em grupo e muito mais. Exclusivo para quem se cadastrar no site:",
  sucessoP5: "Acompanhe a liberação de seus relatórios no nosso portal, assim que a plataforma de pagamentos confirmar, os relatorios estarão disponíveis para download",
  sucessoP6: "Você já pode acessar o portal clicando",
  sucessoP7: "Ah, lembra de deixar seu comentário nos canais para que mais pessoas tenham oportunidade de nos conhecer, se conhecer e junto com a gente trabalhar para um mundo cada vez melhor!",
  sucessoP8: "Homem de terno e gravata",
  sucessoP9: "Descrição gerada automaticamente. Boa jornada!",
  aqui: "aqui",
  nossosCanais: "Inscreva-se nos nossos canais:",

  //Checkout - pendente
  pendente: "Pendente",
  pendenteP1: "Sua compra se encontra como status pendente. Mas não se preocupe, em pouco tempo você poderá usar seu plano.",

  //Checkout - falha
  falha: "Algo deu errado",
  falhaP1: 'Desculpa, não foi possivel finalizar sua compra. Tente novamente mais tarde ou entre em contato conosco.',

  //Página 404
  erro404: "404 - Página não encontrada",
  erro404P1: "Esto é apenas um contratempo não intencional",
  erro404P2: " Mas sabemos que esta não é página que você está procurando e pedimos desculpas por isso.",
  irPaginaInicial: "IR PARA PÁGINA INICIAL",

  //Página - Contato 
  entreEmContato: "Contato",
  enviar: "Enviar",
  digiteAssunto: "Digite Assunto",
  digiteSeuNome: "Digite seu Nome",
  inserirMensagem: "Inserir Mensagem",
  rua: "Rua Senador Teotônio Vilela N. 110 - Sala 509 Ed. Cidadela Center II - Bairro Parque Bela Vista",
  estado: "Salvador - Bahia, CEP - 40279435",
  telefone: "+55 21 97340-8963",
  horarios: "Segunda a Sexta",
  emailContato: "contato@personatest.com.br",
  msgContato: "Envie-nos um e-mail a qualquer momento!",

  


  // Página - Planos
  carregando: "Carregando...",
  otimaEscolha: "ótima escolha",
  paraContinuar: "Para continuar, precisamos que você faça login ou crie uma nova, se você não tiver uma conta.",
  
  fazerLogin: "Fazer login",
  cancela: "Cancelar",
  gratis: "Grátis",
  comprar: "Comprar",
  saibaMais: "Saiba mais",
  cadastreSe: "Cadastre-se",

  "Testes ilimitados": "Testes Ilimitados",
  "Compartilhe nas redes sociais": "Compartilhe nas redes sociais",
  
  // Página - Sobre-nós
  p1: "Você já reparou como é difícil tomar decisões diante de tantas alternativas que a vida oferece, seja no amor, profissional, educacional, promoção da saúde ou relacionamento entre amigos e familiares? Você tem dificuldade em entender quem você é, sendo mais tolerante com seus limites e confiante em suas possibilidades?",
  p2: "O PERSONATEST® é uma ferramenta de mapeamento de perfis comportamentais baseada na teoria de CG JUNG em TIPOS PSICOLÓGICOS e ajudará a superar esses obstáculos, tanto do ponto de vista individual quanto coletivo (empresas, academias, escolas, clínicas, etc.).",
  p3: "Segundo C. G. Jung, existem quatro tipos de perfil comportamental: PENSAMENTO, SENTIMENTO, SENSAÇÃO e INTUIÇÃO. Todas as pessoas têm características dos quatro, não há melhor ou pior, o ideal é o equilíbrio entre elas e cada pessoa tem um tipo superior que determina sua tendência comportamental.",
  p4: "A aplicação dos resultados do PERSONATEST® é abrangente e capaz de contribuir para o desenvolvimento humano em nível individual e coletivo. Em termos individuais, permite o autoconhecimento e a identificação das principais competências cognitivas, emocionais e comportamentais que predominam na persona. É uma ferramenta estratégica essencial para mapear potencialidades, reconhecer limitações, tolerar diferenças e aumentar a aprendizagem de novos papéis que permitem a construção de uma vida plena baseada na autenticidade, liberdade, autonomia e propósito.",
  p5: "Em termos organizacionais, é uma ferramenta única, capaz de fornecer subsídios aos gerentes para promover segurança no trabalho, eficácia no recrutamento e seleção, otimizar o processo de vendas, envolver equipes e treinar líderes. Ele também permite que uma instituição educacional ou academia seja capaz de mapear o perfil dos alunos e treinar educadores para usar técnicas pedagógicas e outras abordagens compatíveis com a tipologia individual ou coletiva, estimulando a aprendizagem, a lealdade e o desempenho.",
  p6: "O PERSONATEST® é o resultado de mais de trinta anos de trabalho e pesquisa do professor Luiz Hosannah na área de desenvolvimento e comportamento humano e mapeia o perfil tipológico daqueles que respondem ao teste, explicando a proporção de cada um dos quatro tipos na composição de sua personalidade",
  p7: "Fácil de entender, simples de falar e rápido de executar, as pessoas com mais de quinze anos poderão identificar seu perfil e se beneficiar dessa incrível ferramenta de autoconsciência. O relatório final é uma excelente oportunidade para o autoconhecimento e a autotransformação, com base na identificação do perfil tipológico muito comportamental.",
  p8: "Precisamente devido às inúmeras possibilidades de aplicação dos resultados PERSONATEST, você pode escolher entre três opções de relatório:",
  p9: "Em termos organizacionais, é uma ferramenta única, capaz de fornecer subsídios aos gerentes para promover segurança no trabalho, eficácia no recrutamento e seleção, otimizar o processo de vendas, envolver equipes e treinar líderes. Ele também permite que uma instituição educacional ou academia seja capaz de mapear o perfil dos alunos e treinar educadores para usar técnicas pedagógicas e outras abordagens compatíveis com a tipologia individual ou coletiva, estimulando a aprendizagem, a lealdade e o desempenho.",
  
  p10: "Nesse modelo, você conhecerá seu TIPO PSICOLÓGICO e receberá um resumo explicativo das tendências comportamentais inerentes ao seu modo de sentir, agir e se expressar. É um bom começo!",
 
  p11: "No entanto, se você reconhecer que é necessário dar um passo adiante, e sua demanda é aprofundar o entendimento de sua estrutura psicodinâmica, uma excelente opção é o plano mais avançado:",

  p12: "Você terá acesso a um relatório descritivo muito mais avançado que mapeia a maneira como você se relaciona com diferentes dimensões de sua vida diária no exercício de várias personas:",
  p13: "Como você aprende e se comunica;",
  p14: "Qual é o seu estilo de liderança e como isso contribui para os processos do grupo;",
  p15: "Como você se sente motivado diante dos desafios;",
  p16: "Quais cargos e funções que melhor se correlacionam com sua tipologia;",
  p17: "Passos iniciais para  equilibrar sua tipologia;",
  p18: "Tarefas organizacionais que podem gerar mais riscos de falhas operacionais, não conformidades e acidentes de trabalho;",
  p19: "Seu perfil de consumidor;",
  p20: "Seus pontos frágeis e necessidades de melhoria em termos comportamentais;",
  p21: "Como você tenta solucionar os problemas no cotidiano;",
  p22: "Como é seu comportamento nas relações amorosas;",
  p23: "Como você lida com a educação dos seus filhos.",

  p24: "O outro modelo é muito mais agregador e abrangente,  tem a missão de instrumentar sua jornada de  autotransformação.",

  p25: "esse modelo oferece todo conteúdo dos planos anteriores e inclui o PAP – PLANO DE APRIMORAMENTO PESSOAL E PROFISSIONAL. Esse plano de ação personalizado objetiva instrumentaliza-lo na viabilização do almejado  equilíbrio entre os 4 TIPOS PSICOLÓGICOS. É um roteiro com exercícios e um passo-a-passo detalhado, auto-explicativo,  que contribuirá para um salto qualitativo na construção de seu propósito e integração entre PERSONA e  SOMBRA.",

  p26: "Professor Hosannah graduou-se em 1988 na UFBa, é Mestre em História pela UFBa , especialista em educação, possui formação em Psicanálise e em Psicologia Analítica junguiana, pós-graduação em Psicologia Organizacional e em Psicologia Positiva, foi professor de graduação por mais de duas décadas e atualmente ministra cursos de pós-graduação no Brasil e exterior.",
  p27: "Psicoterapeuta há mais de 30 anos e palestrante nacional carismático, é autor de diversos artigos publicados e consultor de diversas organizações do porte da PETROBRÁS, BRASKEM, CORREIOS, FERBASA, TELEBRÁS, CHESF, PERINI, OAS, HOSPITAL SÃO RAFAEL, BAHIAGÁS, JUCEB, SEBRAE, SESI, FAFEN, CAIXA ECONÔMICA e centenas de outras em todo Brasil.",
  p28: "Viajante, já visitou quase 100 países e criou o projeto VIAJANDO COM PROFESSOR HOSANNAH no canal youtube.com/luizhosannah. Nesse projeto, ele grava vídeos diante de monumentos históricos e  simbólicos ao redor do mundo e correlaciona história, arte, mitologia e diversidade cultural com desenvolvimento humano, inteligência emocional e arquétipos.",
  p29: "Venha se beneficiar dessa ferramenta de tecnologia de ponta, rápida execução, alta fidedignidade e baseada em conhecimento validado cientificamente.",
  p30: "Boa jornada rumo ao autoconhecimento e à melhor versão de si mesmo em uma vida plena e com propósito.",

  p31: "Meus contatos",

  p32: "Testes realizados até o momento",
  p33: "Tem alguma pergunta?",


  // Traduçoes para o Dashboard

  // Header
  meuResultado: "Meu Resultado",
  meusRelatorios: "Meus Relatórios",
 


  //Youtube
  canalYoutube: "Canal do YouTube ",
  novosVideos: "Novos vídeos",

  //Meus Arquivos
  meusArquivos: "Meus Arquivos",
  LIVRO_JUNG_BEM_ESTAR_CONFIABILIDADE_DIGITAL: "LIVRO JUNG BEM ESTAR CONFIABILIDADE",
  TIPOS_PSICOLÓGICOS: "TIPOS PSICOLÓGICOS",
  papEmPdf: "Plano de Aprimoramento Pessoal",
  baixarLivro: "Baixar Livro",
  baixarEbook: "Baixar E-book",

  facaParteComnunidadeTelegram: "Faça parte da Comunidade Persona Test no Telegram",
  tenhaAcesso: "e tenha acesso a inúmeras e exclusivas informações. Tire dúvidas, participe de discussões e mentoria em grupo e muito mais, com o Professor Luiz Hosannah.",
  pagamentoPendete: "O seu pagamento esta sendo processado e assim que confirmado seu relatório estará disponivel aqui...",
  baixarPDF: "Baixar seu PDF",
  baixarPAP: 'Baixar PAP',
  anterior: "Anterior",
  proximo: "Próximo",
  
  //Perfil
  tipoPsicologico: "Tipo Psicológico",
  compartilhar: "Compartilhar",
  erroAoSalvar: "Houve um erro ao salvar. Por favor, tente novamente mais tarde.",
  qntTestesRealizados: "Testes realizados",
  minhaConta: "Minha conta",
  informacaoUsuario: "Informação do usuário",
  nomeUsuario: "Nome do usuário",
  emailUsuario: "Endereço de e-mail",
  cnpjUsuario: "CNPJ",
  nascimentoUsuario: "Data de Nascimento",
  contatoUsuario: "Informações de Contato",
  telefoneUsuario: "Telefone",
  numeroTelefone: "Número de Telefone",
  cidadeUsuario: "Cidade",
  seuCidade: "Sua cidade",
  estadoUsuario: "Estato",
  selecionarEstado: "Selecionar",
  paisUsuario: "País",
  seuPais: "Seu País",
  alterarSenha: "Alterar senha",
  novaSenha: "Nova senha",
  digiteNovaSenha: "Digitar nova senha",
  cadastroAtualizado: "Cadastro atualizado",


  perfil: "Meu Perfil",
  relatorio: "Relatórios",
  pap: "PAP",
  Dashboard: "Dashboard",
  email: "Email",
  mensagens: "Mensagens",
  bemVindo: "Bem-vindo(a)!"



}
export default pt;
