import React from 'react'
import { useTranslation } from 'react-i18next';
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap'
import PropTypes from 'prop-types'
const urlCMS = 'https://cms.personatest.com.br'

export default function CardDepoimento(props){
  const { t, i18n } = useTranslation();
  return(
    <>
      {props.ativo ? 
          <Card style={{paddingBottom: 30}}  body outline color="success">
            {props.imagem  ? 
              <CardImg top width="100%" src={`${urlCMS}${props.imagem}`} alt="Card image cap" /> : null 
            }
            <CardBody>
              <CardText>
              <blockquote 
                dangerouslySetInnerHTML={{__html: `&quot;${props.texto}&quot;`}}>
              </blockquote>
              </CardText>
              <CardTitle>{props.nome}</CardTitle>
              <CardText>
                <small className="text-muted">{t('data')}: {props.data}</small>
              </CardText>
            </CardBody>
          </Card>
       : null}
    </>
    
  )
}

CardDepoimento.propTypes = {
  ativo: PropTypes.bool,
  nome: PropTypes.string,
  imagem: PropTypes.string,
  texto: PropTypes.string,
  data: PropTypes.string
}