/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect, useContext} from "react";
import { useTranslation } from 'react-i18next';
import DatePicker,  { registerLocale } from "react-datepicker";
import { format, parse } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import "react-datepicker/dist/react-datepicker.css";

import api from '../../services/api'
import Context from '../../utils/context'
import ModalInfo from '../../components/Modals/ModalInfo'
import {login} from '../../services/auth'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// core components
import UserHeader from "../components/Headers/UserHeader.jsx";

registerLocale("pt-br", pt)
export default function Profile ({history}){
    const context = useContext(Context)
    const { t, i18n } = useTranslation();
    const [ nome, setNome ] = useState('')
    const [ totalTests, setTotalTests ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ date, setDate ] = useState('')
    const [ pais, setPais ] = useState('')
    const [ cidade, setCidade ] = useState('')
    const [ estado, setEstado ] = useState('')
    const [ celular, setCelular ] = useState('')
    const [cnpj , setCnpj] = useState('')
    const [ senha, setSenha ] = useState('')
    const [ token, setToken ] = useState('')
    const [modalSucesso, setModalSucesso] = useState(false);
    const [modalSucessoLogin, setModalSucessoLogin] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [textModal, setTextModal] = useState('');
    
    function toggleSucesso(){
      setModalSucesso(!modalSucesso);
    }
    function toggleSucessoLogin(){
      setModalSucesso(!modalSucesso);
    }
    function toggleError(){ 
      setModalError(!modalError);
    }


    useEffect(()=>{
      getCouterTests(context.getUser.usuario.email)
      if(context.getUser.localizacao.endereco !== null){
        
      }else{
        setCidade('')
      }
      setCidade(context.getUser.localizacao.cidade)
      setEstado(context.getUser.localizacao.uf)
      setPais(context.getUser.localizacao.pais)
      setCelular(context.getUser.usuario.celular)
      setCnpj(context.getUser.documento
        )

      if(context.getUser.usuario.nascimento !== ""){
        console.log(context.getUser.usuario.nascimento);
        const d = parse(`${context.getUser.usuario.nascimento}`, 'yyyy-MM-dd', new Date())
        console.log(d);
        setDate(d)
      }
      // console.log(format(new Date('2020-07-10T14:14:12.420Z'), 'dd/MM/yyyy'))
      // console.log(format(new Date(`${context.getUser.usuario.nascimento}`), 'dd/MM/yyyy'))
      // setDate(format(new Date(`${context.getUser.usuario.nascimento}`), 'yyyy-MM-dd'))


      setNome(context.getUser.usuario.nome)
      setEmail(context.getUser.usuario.email)
      setSenha(context.getUser.usuario.senha)
      setToken(context.getUser.token)
      //console.log(context)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function getCouterTests(email){
      api.get(`/statistic/tests/${email}`).then(res => {
        const { realizados } = res.data[0];
        setTotalTests(realizados)
      }).catch(err => {
        console.log(err)
      })
    }

    async function hendleUpdataPerfil(e){
      e.preventDefault()
      let _dateF = ''
      if(date){
        _dateF = getFormatDate(date)
      }
      console.log(JSON.stringify({email,
        nascimento: _dateF,
        pais,
        cidade,
        estado,
        celular,
        senha,
        token}))
      try {
        await api.post("funtions/updatecadastro",{
          email,
          nascimento: _dateF,
          pais,
          cidade,
          estado,
          celular,
          senha,
          token
        }).then((res)=>{
          console.log(res)
          const { resultado } = res.data
          if(senha){
            context.logout()
            return history.push('/login')
          }
          setTextModal(resultado)
          toggleSucesso();
          hendleLogin(email, context.getUser.usuario.senha);
        }).catch((error)=>{
          const { data } = error.response;
          console.log(data)
          setTextModal(data.errors.message)
          toggleError();
        })
      } catch (error) {
        console.log(error)
        setTextModal(t('erroAoSalvar'))
        toggleError();
      }
    }

    function hendleLogin(senha, email){
      login(senha,email).then((res)=>{
        console.log(res)
          // if(res === "sucess"){
          //     redirect()
          // }else{
          //     setError(res);
          // }
      }).catch((error)=>{
         console.log(error)
      })
    }

    function getFormatDate(date){
      console.log('date', date);
      const _NewData = format(new Date(date), 'dd-MM-yyyy')
      return  _NewData
    }

    function getParceDate(date){
      console.log('date', date);
      const _NewData = parse(`${date}`, 'yyyy-MM-dd', new Date())
      return  _NewData
    }
// Função para aparece o cnpj quando o usuario logar em Pessoa Juritica 
    function PJ(cnpj){
      if(cnpj > 0){
        return <FormGroup>
        <label
          className="form-control-label"
          htmlFor="input-email"
        >
          {t('cnpjUsuario')}
        </label>
        <Input
          className="form-control-alternative"
          id="input-cnpj"
          defaultValue={cnpj}
          placeholder="cnpj"
          type="text"
          disabled
        />
      </FormGroup>
      }
    }

    
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../assets/img/theme/user_profiler.jpg")}
                      />
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    {/* <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Conectar
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Mensagem
                    </Button> */}
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">{totalTests}</span>
                          <span className="description">{t('qntTestesRealizados')}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {context.getUser.usuario.nome}
                      {/* <span className="font-weight-light">, 27</span> */}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {/* {context.getUser.localizacao.endereco} */}
                    </div>
                    {/* <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Engenheiro de Software - VSE Tecnologia
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Univercidade Cruzeiro do Sul
                    </div> */}
                    {/* <hr className="my-4" />
                    <p>
                      Ryan — the name taken by Melbourne-raised, Brooklyn-based
                      Nick Murphy — writes, performs and records all of his own
                      music.
                    </p>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Show more
                    </a> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{t('minhaConta')}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={hendleUpdataPerfil}
                        size="sm"
                      >
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {t('informacaoUsuario')}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {t('nomeUsuario')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={nome}
                              id="input-username"
                              placeholder={t('nomeUsuario')}
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {t('emailUsuario')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={email}
                              placeholder="email@email.com"
                              type="email"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          {PJ(cnpj)}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-date"
                            >
                             
                            </label>
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={date}
                              locale="pt-br"
                              onChange={data => setDate(data)}
                              placeholderText={t('nascimentoUsuario')}
                              className="form-control-alternative"
                              id="input-date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                    {t('contatoUsuario')}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-telefone"
                            >
                              {t('telefoneUsuario')}
                            </label>
                            <Input
                              style={{color: 'black'}}
                              className="form-control-alternative"
                              id="input-telefone"
                              pattern="([0-9]{3}) [0-9]{5}[0-9]{4}"
                              placeholder={t('telefoneUsuario')}
                              value={celular}
                              onChange={e=> setCelular(e.target.value)}
                              type="tel"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              {t('cidadeUsuario')}
                            </label>
                            <Input
                              style={{color: 'black'}}
                              className="form-control-alternative"
                              value={cidade}
                              id="input-city"
                              placeholder={t('seuCidade')}
                              onChange={e=> setCidade(e.target.value)}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-estado"
                            >
                              {t('estadoUsuario')}
                            </label>
                            <Input
                              style={{color: 'black'}}
                              className="form-control-alternative"
                              value={estado}
                              type="select"
                              placeholder={t('estadoUsuario')}
                              onChange={e=> setEstado(e.target.value)}
                            
                            >
                              <option value="Selecionar">{t('selecionarEstado')}</option>
                              <option value="AC">AC</option>
                              <option value="AL">AL</option>
                              <option value="AP">AP</option>
                              <option value="AM">AM</option>
                              <option value="BA">BA</option>
                              <option value="CE">CE</option>
                              <option value="DF">DF</option>
                              <option value="ES">ES</option>
                              <option value="GO">GO</option>
                              <option value="MA">MA</option>
                              <option value="MT">MT</option>
                              <option value="MS">MS</option>
                              <option value="MG">MG</option>
                              <option value="PA">PA</option>
                              <option value="PB">PB</option>
                              <option value="PR">PR</option>
                              <option value="PE">PE</option>
                              <option value="PI">PI</option>
                              <option value="RJ">RJ</option>
                              <option value="RN">RN</option>
                              <option value="RS">RS</option>
                              <option value="RO">RO</option>
                              <option value="RR">RR</option>
                              <option value="SC">SC</option>
                              <option value="SP">SP</option>
                              <option value="SE">SE</option>
                              <option value="TO">TO</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-pais"
                            >
                              {t('paisUsuario')}
                            </label>
                            <Input
                              style={{color: 'black'}}
                              className="form-control-alternative"
                              value={pais}
                              id="input-pais"
                              placeholder={t('seuPais')}
                              onChange={e=> setPais(e.target.value)}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                    {t('alterarSenha')}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-pass"
                              >
                                {t('novaSenha')}
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-pass"
                                placeholder={t('digiteNovaSenha')}
                                onChange={e=> setSenha(e.target.value)}
                                type="password"
                              />
                          </FormGroup>
                        </Col>
                      </Row>
                      </div>
                    {/* <hr className="my-4" /> */}
                    {/* Description */}
                    {/* <h6 className="heading-small text-muted mb-4">Sobre mim</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Sobre mim</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          rows="4"
                          defaultValue="Um lindo painel para o Bootstrap 4. É gratuito e
                           Código aberto.."
                          type="textarea"
                        />
                      </FormGroup>
                    </div> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalInfo 
          isOpen={modalSucesso} 
          toggle={() => toggleSucesso()} 
          handleButton={() => toggleSucesso()}
          lebalButton={t('estaBem')}>
          {t('cadastroAtualizado')}
        </ModalInfo>
        <ModalInfo 
          isOpen={modalError} 
          toggle={toggleError} 
          handleButton={toggleError}
          lebalButton={t('estaBem')}>
          {textModal}
        </ModalInfo>
      </>
    );
}