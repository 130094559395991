import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import api  from '../../../services/api'
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, LinkedinShareButton,  EmailShareButton  } from 'react-share'
import {  FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LinkedinIcon, EmailIcon } from 'react-share'
import HeaderArquivos from "./HeaderArquivos";
import { Card, CardBody, CardHeader, Button, Container, Row, Col } from "reactstrap";
import Context from "../../../utils/context";
import { parseArrayMapResDet, createNewData } from '../../../utils/Charts/ChatsFunctionUtils'
import ResultadoCharts from '../../../components/Charts/utils/ResultadoCharts';

import ebook from '../../../assets/pdf/ebookhosannahFINAL.pdf';
import livroJung from '../../../assets/pdf/LIVRO_JUNG_BEM_ESTAR_CONFIABILIDADE_DIGITAL.pdf';
import PAP from '../../../assets/pdf/PAP.pdf';

export default function MeusArquivos(){
  const context = useContext(Context);
  const { t, i18n } = useTranslation();
  const [ typeProduto, setTypeProduto ] = useState('');
  const [ msgErro, setMsgErro ] = useState('');
  const [ loading, setLoading ] = useState(true);


    useEffect(()=>{
      getTypeProduto(context.getUser.usuario.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  async function getTypeProduto(email){
    await api.post('purchase/active', { email }
    ).then(res => {
      console.log('rec', res.data[0])
      const { Produto } = res.data[0]
      setTypeProduto(Produto);
      setLoading(false)
    }).catch(err => {
      setMsgErro('Você não tem nenhum compra atualmente')
      console.log(err)
      setLoading(false)
    })
  }

  function showPapEnEbook(){
    return (
      <>
      <Col lg="6" xl="4" >
        <Card className="shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">{t('papEmPdf')}</h2>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <img style={{width: "100%"}} src={require('../../assets/img/capa_pdf/capa_pap.JPG')} />
          </CardBody>
          <Button
              color="info"
              className="s-btn mr-2"
              onClick={() => downloadPDF('pdf', PAP, 'Plano_de_Aprimoramento_Pessoal')}
              >
              {t('baixarPAP')} <i className="fa fa-chevron-right"></i>
          </Button>
        </Card>
      </Col>
      <Col lg="6" xl="4" >
        <Card className="shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">{t('TIPOS_PSICOLÓGICOS')}</h2>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <img style={{width: "100%"}} src={require('../../assets/img/capa_pdf/capa_tipos_psicologicos.JPG')} />
          </CardBody>
          <Button
                color="info"
                className="s-btn mr-2"
                onClick={() => downloadPDF('pdf', ebook, 'ebookhosannah')}
                >
                {t('baixarEbook')} <i className="fa fa-chevron-right"></i>
            </Button>
        </Card>
      </Col>
      </>
    )
  }

  function showLivro(){
    return (
      <>
      {showPapEnEbook()}
      <Col lg="6" xl="4" >
        <Card className="shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h2 className="mb-0">{t('LIVRO_JUNG_BEM_ESTAR_CONFIABILIDADE_DIGITAL')}</h2>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <img style={{width: "100%"}} src={require('../../assets/img/capa_pdf/capa_livro_jung.JPG')} />
          </CardBody>
          <Button
              color="info"
              className="s-btn mr-2"
              onClick={() => downloadPDF('pdf', livroJung, 'LIVRO_JUNG_BEM_ESTAR_CONFIABILIDADE_DIGITAL')}
              >
              {t('baixarLivro')} <i className="fa fa-chevron-right"></i>
          </Button>
        </Card>
      </Col>
    </>
    )
  }

    function downloadPDF(type = '', pdf, name) {
      let linkSource;
  
      if(type === 'pdf'){
        linkSource = pdf
      }else{ 
        linkSource = `data:application/pdf;base64,${pdf}`;
      }
  
      const downloadLink = document.createElement("a");
      const fileName = `${name}.PDF`;
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    return(
        <>
            <HeaderArquivos />
            <Container className="mt--7" fluid>
              {loading ? <h1>{t('carregando')}</h1> :
                <Row>
                  {typeProduto === 'Officer' ? showPapEnEbook() 
                  : typeProduto === 'Leader' ? showLivro() : <h1>{msgErro}</h1>}
                </Row> }
            </Container>
        </>
    );
}