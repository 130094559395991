import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import api from '../../services/api'

export default function RecuperarSenha(){
    const { token } = useParams()
    const [ erros, setErros ] = useState(null);
    const [loading, setLoading ] = useState(true);
 
    useEffect(() => {
      api.get(`user/confirmEmail/${token}`)
        .then(res => {
            const { emailConfirmado, geral } = res.data;
            if(emailConfirmado){
                return window.location = '/login'
            }else{
                setLoading(false)
                setErros({geral})
            }
            
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
            setErros({geral: 'Erro interno de servidor. API  respondeu com status 500'})
        })
    },[setErros, token])
    return(
        <>
        <Header/>
        <div className="bradcam_area" style={{marginTop: 60, background: "#f7fafc"}}>
            <h3 style={{color: "#525f7f"}}>CONFIRMAÇÃO DE EMAIL</h3>
        </div>
         <section className="contact-section" style={{ background: "#FFF"}}>
            <div className="container">
              {loading ? 
              <div className="row">
                <div>
                    <h2 className="contact-title" >Carregando</h2>
                </div>
              </div> : 
              <div className="row">
                {erros && <div className="col-12">
                    <h2 className="contact-title" >{erros.geral}</h2>
                </div>}
              </div> }
            </div>
        </section>
        <Footer/>
        </>
    );
}