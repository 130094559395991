import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group';
import Questao from '../../components/Quiz/Questao';
import QuestaoCout from '../../components/Quiz/QuestaoCout';
import Respostas from '../../components/Quiz/Respostas';
import Resultado from '../../components/Quiz/Resultado';
import {Event} from "../../components/Tracking";
import api from '../../services/api'
import axios from 'axios'

import { parseArrayMapResDet, createNewData } from '../../utils/Charts/ChatsFunctionUtils'

export default function Quiz(props){
    const { t, i18n } = useTranslation();
    const [ result, setResult ] = useState('')
    const [ datasete, setDatasete ] = useState({});
    const [ titulo, setTitulo ] = useState('');
    const [ load, setLoad ] = useState(false);
    const [ erroLoad, setErroLoad ] = useState(false);

    function getIdioma(){
        const lng = i18n.languages[0];
        if(lng === 'en') return 2
        else if (lng === 'es') return 3
        else return 1
    }

    function renderAnswerOptions(key) {
        return (
          <Respostas
            key={key.resposta}
            answerContent={key.resposta}
            answerType={key.id}
            answer={props.resposta}
            questionId={props.questionId}
            onAnswerSelected={props.onAnswerSelected}
          />
        );
    }

    function renderQuiz(){
        return(
            <div key={props.questaoId}>
                <Questao pergunta={props.questao}/>
                <QuestaoCout atual={props.progress}/>
                <div>
                    <ul className="answerOptions">
                        {props.respostaOpicoes.map(renderAnswerOptions)}
                    </ul>
                </div>
                <div className="button_start">
                    {props.next && <button type="submit" onClick={props.showButton} >PRÓXIMA</button>}
                </div>
            </div>
        );
    }

    function renderResultado(){
        if(props.completed === true && load===false){
            sendTestQuiz(props.email,props.data);
            //gravaQuestionario(props.email,props.data);
            // Event('USUÁRIO', 'Teste finalidade');
            setLoad(true);
        }
        return(
            <>
            { erroLoad ? 
                <div className="slider_text text-center">
                    <div className="button_start">
                        <button type="submit" onClick={() => reloadResultado(props.email) } className="btn btn-primary mt-5">Mostrar Meu Resultado</button>
                    </div>
                </div>    
            :   result ? renderResult() : 
                <div>
                    <h1>Carregando...</h1>
                </div>
             }
            </>
        );
    }

    //Caso der algo erro, função para recarregar o resultado
    function reloadResultado(email){
        Event('BOTÃO', 'Clique', 'Mostrar meu Resultado quando a API der um Erro');
        setErroLoad(false);
        resultado(email)
    }

    async function sendTestQuiz(email,respostas){
        let quizData = {
            email,
            respostas,
            origem: 'N',
            region_name: ''
        }

        // await axios.get(`https://api.ipstack.com/check?access_key=${process.env.REACT_APP_API_IP}&format=1`)
        // .then(res => {
        //     quizData.region_name = res.data.region_name;
        // })
        console.log('quizData', quizData)
        await api.post('funtions/test/send', quizData).then((response)=>{
            const data  = response.data;
            console.log('data', data)
            setTitulo(data[0].Eixo);
            setResult(data[0].Descricao); 
            chatsData(data)
            
        }).catch((e)=>{
            setErroLoad(true)
            console.error(e.name + ': ' + e.message)
        })
    }

    function chatsData(data){
        let chats = [];
        data.map((item, key) => {
            if(key === 0){
                return true;
            }else{
                return chats.push(item)
            }
        })
        console.log('chats', chats)
        const _data = parseArrayMapResDet(chats);
        console.log('_data', _data)
        const _vq = createNewData(_data.tipo,_data.qttipo);
        const _vp = createNewData(_data.tipo,_data.percentual);
        const new_data = [_vq,_vp]
        setDatasete(new_data);
    }

    // 1º grava o questionario
    async function gravaQuestionario(email,data){

        const quizData = {
            email,
            respostas: data
        }
       console.log(JSON.stringify(quizData))
        await api.post('funtions/send/quiz', quizData).then((response)=>{
            resultado(quizData.email);
        }).catch((error)=>{
            setErroLoad(true)
            console.log(error);
        })
    }

    // 2º obter o id superior e id inferior
    async function resultado(email){
        await api.get(`funtions/eixos?email=${email}`).then((response)=>{
            const { idTipoSuperior, idTipoAuxiliar } = response.data[0];
            getEixosRespastas(idTipoSuperior,idTipoAuxiliar, email);
        }).catch((error)=>{
            setErroLoad(true)
            console.log(error);
        })
    }

    async function getEixosRespastas(s,a, email){
        await api.get(`funtions/resumoeixo?idIdioma=1&idSuperior=${s}&idAuxiliar=${a}&origem=N`).then((res)=>{
            console.log('eixos: ',res.data);
            const { data } = res;
            resultadoDetalhado(email);
            setTitulo(data.Eixo);
            setResult(data.descricao); 
        }).catch((error)=>{
            setErroLoad(true)
            console.log(error.message);
            console.log(error.response);
        })
    }

    async function resultadoDetalhado(email){
        await api.get(`funtions/respostas?email=${email}&detalhe=true`).then((res)=>{
            const _data = parseArrayMapResDet(res.data);
            const _vq = createNewData(_data.tipo,_data.qttipo);
            const _vp = createNewData(_data.tipo,_data.percentual);
            const new_data = [_vq,_vp]
            setDatasete(new_data);
        }).catch((error)=>{
            setErroLoad(true)
            console.log(error);
        });
    }

    function renderResult() {
        Event('TESTE', 'Finalizado', `${titulo}`, true);
        return <Resultado email={props.email} titulo={titulo} texto={result} datasete={datasete} completed={props.completed}/>;
    }
    
    return(
        <>
        <CSSTransition
            className="container"
            timeout={500}
            component="div"
            transitionName="fade"
            transitionEnterTimeout={800}
            transitionLeaveTimeout={500}
            transitionAppear
            transitionAppearTimeout={500}
        >
            { props.completed ?  renderResultado() : renderQuiz() }

        </CSSTransition>
        </>
    );
}

Quiz.propTypes = {
    resposta: PropTypes.string.isRequired,
    respostaOpicoes: PropTypes.array.isRequired,
    questao: PropTypes.string.isRequired,
    questaoId: PropTypes.number.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
    progress: PropTypes.string.isRequired,
    next: PropTypes.bool.isRequired,
    showButton: PropTypes.func.isRequired,

    completed: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,

    tipo_eixo: PropTypes.string.isRequired,
    texto_eixo: PropTypes.string.isRequired,

  };