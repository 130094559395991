import React,{useEffect} from 'react';
import './App.css';
import {PageView, initGA} from './components/Tracking';
import ContextState from './ContextState'

initGA('UA-106127269-4');

function App() {

  useEffect(()=>{
    PageView();
  },[])

  return (
      <>
        <ContextState/>
      </>
  );
}

export default App;
