import { registre, isAuthenticated } from '../services/auth'
import md5 from 'md5'

const isLogged = isAuthenticated()

export const newRegistro = (username, email) => new Promise((resolve, reject) =>{
  const token = md5(`${email}#p3rs0n@t3$t`);

  if(isLogged){
    console.log('isLogged: ',isLogged)
    resolve({token, novoUsuario: false})
  }else{
    const randomPassword = Math.random().toString(36).slice(-8);

    registre(username, email, randomPassword).then((res)=>{
      console.log('Criando um novo registro: ')
      if(res === true){
        resolve({token, novoUsuario: true})
      }
    }).catch(e =>{
      console.log('Erro: Criando um novo registro: ', e)
      reject(e)
    })
  }
});

export const createPurchase = (username, email) => new Promise((resolve, reject) =>{
  
});