import socketio from 'socket.io-client';
// http://localhost:3333 local
// https://api-personatest.herokuapp.com production
const socket = socketio('https://api-personatest.herokuapp.com', {
  autoConnect: false
})

function connect(){
  socket.connect();
}

function disconnect(){
  if(socket.connected){
    socket.disconnect();
  }
}

function subcribeCounterTest(func){
  socket.on('counter_test', func)
}

export {
  connect,
  disconnect,
  subcribeCounterTest
};
