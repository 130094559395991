import React , {useContext, useState, useEffect, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import { Card, CardBody, Spinner, Button, Container, Row, Col } from "reactstrap";

import HeaderRelatorio from './HeaderRelatorio'
import Context from "../../../utils/context";

import { Document, Page } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";
import './pdf.css'

export default function Relatorios(){
  const { t, i18n } = useTranslation();
  const context = useContext(Context)
  const [ loading, setLoading ] =  useState(true)
  const [ compraInfo, setCompraInfo ] = useState(null)
  const [ resultato, setResultato ] = useState('Carregando...')
  const [ relatorioBase64, setRelatorioBase64 ] = useState('')
  const [msgErro, setMsgErro] = useState('')
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    console.log('user',context.getUser.usuario.email)
    getCompras(context.getUser.usuario.email);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log('onDocumentLoadSuccess')
    setNumPages(numPages);
  };

  async function getCompras(email){
    await api.post('plan/active', { email }
    ).then(res => {
      console.log('rec', res.data[0])
      const { Resultado, Status, Produto, Relatorio } = res.data[0]
      setResultato(Resultado)
      setCompraInfo({Status, Produto});
      setRelatorioBase64(Relatorio)
      console.log('setLoading')
      setLoading(false)
      console.log({Resultado, Status, Produto, Relatorio })
    }).catch(err => {
      setMsgErro('Você não tem nenhum compra atualmente')
      console.log(err)
      setLoading(false)
    })
  }

  function downloadPDF(type = '', pdf, name) {
    let linkSource;

    if(type === 'pdf'){
      linkSource = pdf
    }else{ 
      linkSource = `data:application/pdf;base64,${pdf}`;
    }

    const downloadLink = document.createElement("a");
    const fileName = `${name}.PDF`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

  function showMesagemErro(){
    return(
      <>
      <HeaderRelatorio eixo={''}/>
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="12" xl="12">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                  <Row>
                    <div className="col">
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap">{msgErro}</span>
                      </p>
                    </div>
                  </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </Container>
    </>
    )
  }

  function showRelatorio(){
    if(compraInfo.Produto === 'Free'){
      return( 
        <>
          <HeaderRelatorio eixo={resultato}/>
          <Container className="mt--7" fluid>
            <Row>
              <Col lg="12" xl="12">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                      <Row>
                        <div className="col">
                            <p  dangerouslySetInnerHTML={{__html: relatorioBase64}}>
                            </p>
                        </div>
                      </Row>
                      <Row>
                        <div className="col">
                          <p>
                              Agora que você já conhece qual é o seu TIPO PSICOLÓGICO e recebeu um resumo explicativo das tendências comportamentais inerentes à sua forma de sentir, agir e se expressar, o PERSONATEST quer te convidar a se aprofundar na jornada de autoconhecimento
                          </p>
                        </div>
                      </Row>
                      <Row>
                        <div className="col">
                          <p>
                            Caso você reconheça que é necessário um passo além, e que a sua demanda é ampliar a consciência acerca da sua estrutura psicodinâmica, então uma excelente opção é um dos  planos mais avançados:
                          </p>
                        </div>
                      </Row>
                      <Row>
                        <div className="col mt-2">
                          <h3>•	START</h3>
                          <p>{t('p12')} </p>
                          <ol>
                              <li>a)	{t('p13')}</li>
                              <li>b)	{t('p14')}</li>
                              <li>c)	{t('p15')}</li>
                              <li>d)	{t('p16')}</li>
                              <li>e)	{t('p17')}</li>
                              <li>f)	{t('18')}</li>
                              <li>g)	{t('p19')}</li>
                              <li>h)	{t('p20')}</li>
                              <li>i)	{t('p21')}</li>
                              <li>j)	{t('p22')}</li>
                              <li>k)	{t('p23')}</li>
                          </ol>
                        </div>
                      </Row>
                      <Row>
                        <div className="col mt-2">
                          <h3>•	OFFICER</h3>
                          <p> {t('p25')} </p>
                          <p>
                          •	{t('facaParteComnunidadeTelegram')} <a href="https://t.me/joinchat/TVhxcE2fnakxp4SDuGWUjQ">https://t.me/joinchat/TVhxcE2fnakxp4SDuGWUjQ</a>,  {t('tenhaAcesso')} 
                          </p>
                        </div>
                      </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    }else if(compraInfo.Status !== 'approved'){
      return(
        <>
          <HeaderRelatorio eixo={resultato}/>
          <Container className="mt--7" fluid>
            <Row>
              <Col lg="12" xl="12">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                      <Row>
                        <div className="col">
                            <p>
                            {t('pagamentoPendete')}
                            </p>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
      )
    }else{
      return(
        <>
          <HeaderRelatorio eixo={resultato}/>
          <Container className="mt--7" fluid>
            <Row>
              <Col lg="12" xl="12">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    {relatorioBase64 ? 
                      <Button
                          color="info"
                          className="s-btn"
                          onClick={() => downloadPDF('baseg4', relatorioBase64, resultato)}
                          >
                          {t('baixarPDF')} <i className="fa fa-chevron-right"></i>
                      </Button> : null}
                    <div className="pdf-container">
                      <Document
                          file={`data:application/pdf;base64,${relatorioBase64}`}
                          onLoadSuccess={onDocumentLoadSuccess}
                          externalLinkTarget="_blank"
                      >
                          <Page pageNumber={pageNumber} />
                      </Document>
                      <div fluid className="s-padding-t">
                        <Row style={{justifyContent: 'center' }}>
                          <Button
                              color="info"
                              className="s-btn"
                              disabled={pageNumber <= 1}
                              onClick={() => setPageNumber(pageNumber - 1)}
                              >
                              <i className="fa fa-chevron-left"></i> {t('anterior')}
                          </Button>
                          <Button
                              color="info"
                              className="s-btn"
                              disabled={pageNumber >= numPages}
                              onClick={() => setPageNumber(pageNumber + 1)}
                              >
                              {t('proximo')} <i className="fa fa-chevron-right"></i>
                          </Button> 
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    }
  }
  
  return(
    <>
      {
        loading ? <>
        <HeaderRelatorio eixo={resultato}/>
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12" xl="12">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                    <Row>
                      <div className="col">
                        <p>
                          {t('carregando')}
                        </p>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </> 
      :
        <>
          {msgErro ? showMesagemErro() : showRelatorio()}
        </>
      }
      
    </>
  );
}

              


