import React from 'react';
import PropTypes from 'prop-types';

export default function Questao(props){
    return(
        <h1 style={{ paddingBottom: 20}}>{props.pergunta}</h1>
    );
}
Questao.propTypes = {
    pergunta: PropTypes.string.isRequired
};