import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header'
import Footer from '../../components/Footer';

export default function Falhou({history}){
  const { t, i18n } = useTranslation();
  return(
    <>
      <Header/>
      <div className="bradcam_area breadcam_bg overlay2">
          <h3>{t('falha')}</h3>
      </div>
      <div class="about_area">
        <div class="container">
          <div class="row mb-20">
            <div class="col-xl-12">
                <p>{t('falhaP1')}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
  </>
  );
}