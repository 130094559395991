import React,{useState, useEffect, useContext} from "react";
import { useLocation } from 'react-router-dom'
import { registre, login } from '../../services/auth';
import logo from    '../../assets/img/logo.png';
//import loginImg from "../../login.svg";

export class PessoaFisica extends React.Component {

  



  constructor(props) {
    super(props);
    this.state = {nome: ''};
    this.state = {email: ''};
    this.state = {senha: ''};
    this.state = {
      isChecked: false,
    };

    this.nome = this.nome.bind(this);
    this.email = this.email.bind(this);
    this.senha = this.senha.bind(this);
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  
  

  nome(event) {
    this.setState({nome: event.target.value});
  }
  email(event) {
    this.setState({email: event.target.value});
  }
  senha(event) {
    this.setState({senha: event.target.value});
  }
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  
	
  handleSubmit(event) {
    event.preventDefault();
    
    registre(this.state.nome, this.state.email, this.state.senha).then((res) =>{
     alert("Confirme seu email com o link que te enviamos. Verifica a sua caixa de Spam, se necessário.")
    // login(this.state.email, this.state.senha).then((res) => {
    // return window.location = '/login'
    // }).catch((res) => {
    //   alert("Erro com a conexão com o Banco")
    // })
     
    }).catch((err) => {
      console.log(err)
      alert("Erro com a conexão com o Banco")
    })
    
  }


   useQuery() {
    return new URLSearchParams(useLocation().search);
    
}
/*  redirect(){

    if(callback === 'true'){
        return window.location = `/checkout?token=${idToken}`;
    }else{
        return window.location = '/painel/index';
    }
}*/
  render() {
    return (
      <div className="base-container" ref={this.props.containerRef}>
      <div className="image">
          <img src={logo}/>
        </div>
        <br/>
        <div className="header">Pessoa física</div>
        <div className="content">
          
          <form onSubmit={this.handleSubmit} className="form">
            <div className="form-group">
              <label htmlFor="username">Nome Completo</label>
              <input
            name="nome"
            type="text"
            placeholder="Nome Completo"
            value={this.state.nome} 
            onChange={this.nome}
            required
           
            />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
            name="email"
            type="email"
            placeholder="email"
            value={this.state.email}
            onChange={this.email}
            required

            />
            </div>
            <div className="form-group">
              <label htmlFor="password">Senha</label>
              
              <input
            name="senha"
            type="password"
            placeholder="A senha pode conter até 20 caracteres"
            value={this.state.senha}
            onChange={this.senha}
            required
            />
</div>
<div className="checkbox">
            <input name="Aenviar"
            type="checkbox"
            checked={this.state.isChecked}
          onChange={this.toggleChange}
            required/>
         &nbsp; <label>Autorizo enviar informações para este E-mail</label>
</div>            
            <div className="footer">
          <button type="submit" className="btn">
          Cadastrar
          </button>
        </div>
          </form>
        </div>
       
      </div>
    );
  }
}
