import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
export default function NotFouder(){
    const { t, i18n } = useTranslation();
    return(
        <>
        <Header/>
        <div class="lets_launch launch_bg_1 overlay2">
            <div class="launch_text text-center">
                <h3>{t('erro404')}</h3>
                <p>{t('erro404P1')}<br/>
                {t('erro404P2')}</p>
                <div class="chat">
                    <a class="boxed_btn_green" href="/">
                        <span>{t('irPaginaInicial')}</span>
                    </a>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}