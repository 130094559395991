import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import Quiz from '../../components/Quiz';
import ModalInfo from '../../components/Modals/ModalInfo'
import {Event} from "../../components/Tracking";
import api from '../../services/api'


export default function Teste({history}){
    const { t, i18n } = useTranslation();
    const [ currentLang, setCurrentLang ] = useState('');
    const [ teste, setTeste] = useState([]);
    const [ counter, setCounter ] = useState(0);
    const [ questaoId, setQuestaoId ] = useState(1);
    const [ progress, setProgress ] = useState(0);
    const [ questao, setQuestao ] = useState('');
    const [ respostaCout, setRespostaCout ] = useState([]);
    const [ resposta, setResposta ] = useState('');
    const [ respostaOpicoes, setRespostaOpicoes ] = useState([])
    const [ email, setEmail ] = useState('');
    const [ next , setNext ] = useState(false);
    const [ start, setStart ] = useState(false);
    const [ completed, setCompleted ] = useState(false)
    const [modalSucesso, setModalSucesso] = useState(false);
    const [ event, setEventAtual ] = useState('')

    function toggleSucesso(){
        setModalSucesso(!modalSucesso);
    }

    useEffect(()=>{
        getPerguntas();
        setCurrentLang(i18n.language)
        console.log('i18n',i18n.language );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        console.log('Idioma',{currentLang, i18n: i18n.language})
        if(start){
            if(currentLang !== i18n.language){
                toggleSucesso()
                console.log('Você quer mudar o idioma?');
            }
        }else{
            getPerguntas()
            setCurrentLang(i18n.language)
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[i18n.language])

    function cancelModal(){
        setModalSucesso(!modalSucesso);
        i18n.changeLanguage(currentLang);
    }

    function reloadScreem(){
        window.location = '/inicia-teste'
    }
    
    function getIdioma(){
        const lng = i18n.languages[0];
        if(lng === 'en') return 2
        else if (lng === 'es') return 4
        else return 1
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function getPerguntas(){
        let idioma = getIdioma()
        console.log('idioma', idioma)
        try {
            await api.get(`funtions/perguntas?idioma=${idioma}`).then((response) =>{
                const { data } = response;
                //const texto = JSON.stringify(data.texto).replace(/"/g,'');
                setTeste(data);
                console.log('Perguntas: ',data)
                const shuffledAnswerOptions = data.map(item =>
                    shuffleArray(item.respostas)
                );
                setQuestao(data[0].questao);
                setRespostaOpicoes(shuffledAnswerOptions[0]);
                setProgress(progressBar(0));
                //console.log('shuffledAnswerOptions',shuffledAnswerOptions);
            }).catch((error)=>{
                console.log(error)
            });
        } catch (error) {
            console.log(error)
        }
    }
    
    function shuffleArray(array) {
        var currentIndex = array.length,
        temporaryValue,
        randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
        // Pick a remaining element...
        //randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        //array[currentIndex] = array[randomIndex];
        array[currentIndex] = temporaryValue;
        }
        //console.log(array);
        return array;
    }

    function handleAnswerSelected(event) {
        setEventAtual(event.target.value);
        //setUserAnswer(teste[counter].idPergunta, event.target.value);
        if(questaoId < teste.length){
            setNext(true);
            //showButton(teste[counter].idPergunta, event.currentTarget.value);
            //setNextQuestion();
        } else {
            setUserAnswer(teste[counter].id_questao, event.target.value);
            setTimeout(() => setCompleted(true), 300);
        }
    }

    function setUserAnswer(q,r) {
        const res = [
            q,
            r
        ];
        setRespostaCout([...respostaCout,res] || 0);
        setResposta(r);
    }

    function showButton(){
        setUserAnswer(teste[counter].id_questao, event);
        setTimeout(() => setNextQuestion(), 300);
    }

    function setNextQuestion() {        
        const _counter = counter + 1;
        const _questaoId = questaoId + 1;
        setProgress(progressBar(_questaoId));
        setCounter(_counter);
        setQuestaoId(_questaoId);
        setQuestao(teste[_counter].questao);
        setRespostaOpicoes(teste[_counter].respostas);
        setResposta('');
        setNext(false);
    }

    function progressBar(value){
        const v = (( value / 43 ) * 100).toFixed(0);
        return v;
    }

    // -- Funções para iniciar teste --
    function handleChangeEmail(event) {
        setEmail(event.target.value); // Salva no state email
    }
    
    function handleSubmitStartTeste(event) {
        Event('BOTÃO','Clique', 'Digitou um email e iniciou o teste');
        setStart(true); // Se true, inicia teste (Quiz)
        event.preventDefault();
    }
    // -- Fim 

    // -- Formulário de com email e buntão iniciar
    function getEmail(){
        return(
            <>
            <div className="slider_area">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-9">
                            <div className="slider_text text-center">
                                <p style={{fontSize: 20}}>{t('vamosComecarSeuTeste')}</p>
                                <h1>{t('digiteSeuEmail')}</h1><br></br>
                                <div className="find_dowmain">
                                    <form onSubmit={handleSubmitStartTeste} className="find_dowmain_form" >
                                        <input 
                                        className="form-control valid" 
                                        style={{border: "1px solid #000"}}
                                        name="email" 
                                        id="email" 
                                        type="email" 
                                        placeholder="Email" 
                                        autoComplete="username" 
                                        value={email} 
                                        onChange={handleChangeEmail} 
                                        required/>
                                        <button type="submit">{t('iniciar')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
    // -- Fim formulário


    // -- Inciar Quiz
    function renderQuiz() {
        return (
            <>
            {!teste.length > 0 ? 
                <h4>Carregando</h4>
            :
            <Quiz
                resposta={resposta}
                respostaOpicoes={respostaOpicoes}
                questao={questao}
                questaoId={questaoId}
                onAnswerSelected={handleAnswerSelected}
                progress={progress}
                next={next}
                showButton={showButton}
                data={respostaCout}
                email={email}
                completed={completed}
                //texto_eixo={texto}
            />
            }
            </>
        );
    }
    // -- FIm quiz

    return(
        <>
         <Header/>
            <div className="bradcam_area breadcam_bg overlay2">
                
            </div>
            <div className="package_prsing_area" style={{background: "#fff"}}>
                <div className="container">
                    {start ? renderQuiz() : getEmail()}
                </div>
            </div>
        <Footer/>
        <ModalInfo 
          isOpen={modalSucesso} 
          toggle={() => cancelModal()} 
          handleButton={() => reloadScreem()}
          lebalButton={t('estaBem')}
          handleButton2={() => cancelModal()}
          lebalButton2={t('cancelar')}>
          {t('Você quer mudar o idioma?')}
        </ModalInfo>
        </>
    );
}