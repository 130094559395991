import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header'
import Footer from '../../components/Footer';

export default function Contato(){
    const { t, i18n } = useTranslation();
    return(
        <>
        <Header/>
        <div className="bradcam_area breadcam_bg_4_tipos" style={{
            marginTop: 80, 
            backgroundSize: "contain"
            }}>
        </div>
        <section className="contact-section" style={{ background: "#FFF"}}>
            <div className="container">
                {/* <div className="col-xl-12">
                    <h1 className="contact-title" style={{textAlign: "center", marginBottom: 30, fontSize: 60, fontWeight: 600, }} >Contato</h1>
                </div> */}
                <div className="row">
                    <div className="col-12">
                        <h2 className="contact-title" >{t('entreEmContato')}</h2>
                    </div>
                    <div className="col-lg-8">
                        <form className="form-contact contact_form" action="contact_process.php" method="post" id="contactForm" novalidate="novalidate">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Inserir mensagem'" placeholder={t('inserirMensagem')}></textarea>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input className="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Digite seu nome'" placeholder={t('digiteSeuNome')} autoComplete="username"></input>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input className="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Digite seu email'" placeholder="Email" autoComplete="username"></input>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <input className="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Digite o Assunto'" placeholder={t('digiteAssunto')}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <button type="submit" className="button button-contactForm boxed-btn">{t('enviar')}</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-3 offset-lg-1">
                        <div className="media contact-info">
                            <span className="contact-info__icon"><i className="ti-home"></i></span>
                            <div className="media-body">
                                <h3>{t('rua')}
                                </h3>
                                <p>{t('estado')}</p>
                            </div>
                        </div>
                        <div className="media contact-info">
                            <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                            <div className="media-body">
                                <h3>{t('telefone')}</h3>
                                <p>{t('horarios')}</p>
                            </div>
                        </div>
                        <div className="media contact-info">
                            <span className="contact-info__icon"><i className="ti-email"></i></span>
                            <div className="media-body">
                                <h3>{t('emailContato')}</h3>
                                <p>{t('msgContato')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    );
}