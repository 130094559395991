import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

export default function ModalInfo(props){
  return(
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
        {props.children}
      </ModalBody>
      <ModalFooter>
      <Button style={{backgroundColor: "#670080", color: "#fff"}} onClick={props.handleButton}>{props.lebalButton}</Button>
      {props.handleButton2 &&  <Button style={{backgroundColor: "#670080", color: "#fff"}} onClick={props.handleButton2}>{props.lebalButton2}</Button>}
      </ModalFooter>
    </Modal>
  );
}

ModalInfo.propsTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleButton: PropTypes.func.isRequired,
  lebalButton: PropTypes.string.isRequired,
  handleButton2: PropTypes.func,
  lebalButton2: PropTypes.string
}