import React from 'react';
import Slider from  '../../components/Slider'
import {Event} from "../../components/Tracking";
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import { disconnect } from '../../services/socket'

export default function Home({history}){
    
    function next(){
        disconnect();
        Event('BOTÃO', 'Clique', 'Ir para a página de teste');
        history.push('/inicia-teste');
    }

//    async function getPerguntas(){
//        try {
//             await api.get('eixos/1/3/1').then((response) =>{
//                 const { data } = response;
//                 const texto = JSON.stringify(data.texto).replace(/"/g,'');
//                 setData(texto);
//             }).catch((error)=>{
//                 console.log(error)
//             });
//        } catch (error) {
//         console.log(error)
//        }
//     }

    return(
        <>
            <Header/>
                <Slider go={next}/>
            <Footer/>
        </>
    );
}