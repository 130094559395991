import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Md5 from './pages/Md5';

import Home from './pages/Home';
import Teste from './pages/Teste';
import Depoimentos from './pages/Depoimentos';
import EnviarDepoimento from './pages/Depoimentos/EnviarDepoimento';
import Planos from './pages/Planos';
import PlanosPJ from './pages/Planos/planosPJ';
import Checkout from './pages/Checkout';
import Sucesso from './pages/Checkout/sucesso';
import Pendente from './pages/Checkout/pendente';
import Falhou from './pages/Checkout/falhou';
import Sobre from './pages/Sobre';
import Contato from './pages/Contato';
import NotFouder from './pages/404';
import ObterResultados from './pages/ObterResultados';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';

import Login from './pages/Login';
import Logout from './components/Auth/logout';
import Registro from './pages/Registro';
import RecuperarSenha from './pages/RecuperarSenha';
import ConfirmaEmail from './pages/ConfirmaEmail';

import Context from "./utils/context";

import AdminLayout from "./Dashboard/Layouts/Admin.jsx";

export default function Routes(){

    const context = useContext(Context);
    const PrivateRoute = ({ component: Component, redirect, ...rest }) => {
    return (
      
        <Route
          {...rest}
          render={props =>
            context.isAuthenticated() ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: redirect, state: { from: props.location } }} />
            )
          }
        />
      );
    }


      const AuthenticatedRoute = ({ component: Component, redirect, ...rest }) => (
      
        <Route
          {...rest}
          render={props =>
            !context.isAuthenticated() ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: redirect, state: { from: props.location } }} />
            )
          }
        />
      );

    return(
        <BrowserRouter>
            <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/inicia-teste"  component={Teste} />
                    <Route path="/depoimentos"  component={Depoimentos} />
                    <Route path="/novo-depoimento"  component={EnviarDepoimento} />
                    <Route path="/planos"  component={Planos} />
                    <Route path="/planos-para-empresas"  component={PlanosPJ} />
                    <Route path="/sobre-nos"  component={Sobre} />
                    <Route path="/contato"  component={Contato} />
                    <Route path="/meu-resultado"  component={ObterResultados} />
                    <Route path="/logout"  component={Logout} />
                    <Route path="/md5"  component={Md5} />
                    <Route path="/politica-de-privacidade"  component={PoliticaPrivacidade} />
                    
                    <PrivateRoute path="/checkout"  component={Checkout} redirect="/login"/>
                    <PrivateRoute path="/compra-finalizada"  component={Sucesso} redirect="/login"/>
                    <Route path="/compra-pendente"  component={Pendente} />
                    <Route path="/compra-falhou"  component={Falhou} />

                    <AuthenticatedRoute path="/login"  component={Login} redirect="/painel/index"/>
                    <AuthenticatedRoute path="/registro"  component={Registro} redirect="/painel/index"/>
                    <AuthenticatedRoute path="/recuperar-senha" component={RecuperarSenha} redirect="/painel/index"/>
                    <AuthenticatedRoute path="/confirma-email/:token"  component={ConfirmaEmail} redirect="/painel/index"/>
                    
                    <PrivateRoute path="/painel/index"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/meu-resultado"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/relatorios"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/meus-arquivos"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/pap"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/mentoria"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/video-aulas"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/perfil"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/planos"  component={AdminLayout} redirect="/login"/>
                    <PrivateRoute path="/painel/Dashboard"  component={AdminLayout} redirect="/login"/>
                    {/* <Route path="/painel" component={AdminLayout} /> */}
                    <Redirect exact from="/painel" to="/painel/index" />
                    <Route path="*" component={NotFouder}/>
            </Switch>
        </BrowserRouter>
    );
}