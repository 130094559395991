import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import CardDepoimento from '../../components/Cards/CardDepoimento'
import { CardColumns } from 'reactstrap'

export default function Depoimentos({history}){
  const { t, i18n } = useTranslation();
  const [ depoimentos, setDepoimentos ] = useState(null)
  useEffect(() => {
    //getDepoimentos()
    getComentarios()
  },[])

  async function getDepoimentos(){
    await axios.post(`https://cms.personatest.com.br/api/collections/get/depoimentos?token=e1fca3527f0615d1a4c66017ebb84e`)
    .then(res => {
        const { entries } = res.data
        console.log(entries)
        setDepoimentos(entries)
    }).catch(err => {
        console.log(err)
    })
  }

  async function getComentarios(){
    await axios.post(`https://cms.personatest.com.br/api/collections/get/comentarios?token=e1fca3527f0615d1a4c66017ebb84e`,
    {
        filter: {
          publicado: true
        },
      fields: {
          publicado: 1, nome: 1, texto: 1, data: 1
        },
        sort: {
          _created: -1
        }
    })
    .then(res => {
        const { entries } = res.data
        console.log(entries)
        setDepoimentos(entries)
    }).catch(err => {
        console.log(err)
    })
  }

  return(
    <>
      <Header/>
      <div className="bradcam_area breadcam_bg_4_tipos" style={{
          marginTop: 80, 
          backgroundSize: "contain"
          }}>
      </div>
        <div className="package_prsing_area" style={{background: "#fff"}}>
          <div className="container">
            <div className="col-xl-12">
              <h1 className="contact-title" style={{textAlign: "center", marginBottom: 30, fontSize: 60, fontWeight: 600, }} >{t('depoimentos')}</h1>
            </div>
            <div className="row">
              <CardColumns>
              {depoimentos === null ? <h1>{t('carregando')}</h1> :
                depoimentos.map((item, key)=> (
                  <CardDepoimento
                  key={key} 
                  ativo={item.publicado}
                  nome={item.nome} 
                  texto={item.texto}
                  data={item.data}/>
                ))
              }
              </CardColumns>
            </div>
          </div>
        </div>
      <Footer/>
  </>
  );
}