import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
require('dotenv').config()

ReactDOM.render(
  <Suspense fallback={(<h1>Carregando...</h1>)}>
    <App /> 
  </Suspense>, document.getElementById('root'));
