import JwtDecode from "jwt-decode";

const USER_DATA  = "@user-Data";
const TOKEN_KEY = "@perstest-Token";
export const getUser= () => {
  const user = decodeToken()
  if(user === null){
    return {
      idPessoa:"",
      idEmpresa: "",
      documento: "",
      nomefantasia: "",
      usuario:{
        nome: "",
        email: "",
        senha: "",
        nascimento: "",
        celular: "",
        
      },
      localizacao:{
        endereco: "",
        cep: "",
        cidade: "",
        uf: "",
        pais: "",
      },
      datacadastro: "",
      token: ""
    }
  }else{
    return user
  }
}

export const setUser = (email,senha,user_data) => {
  const userData = {
    idPessoa: user_data.idPessoa,
    idEmpresa: user_data.idEmpresa,
    documento: user_data.documento,
    nomefantasia: user_data.nomefantasia,
    usuario:{
      nome: user_data.nome,
      email,
      senha,
      nascimento: user_data.nascimento,
      celular: user_data.celular,
      
    },
    localizacao:{
      endereco: user_data.endereco,
      cep: user_data.cep,
      cidade: user_data.cidade,
      uf: user_data.uf,
      pais: user_data.pais,
    },
    datacadastro: user_data.datacadastro
  }
  localStorage.setItem(USER_DATA, JSON.stringify(userData));
};

const decodeToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if(token){
    try{
      const decode = JwtDecode(token);
      console.log('decode',decode)
      if(decode.exp*1000 > Date.now()){
        const user = {
          idPessoa: decode.idPessoa,
          idEmpresa: decode.idEmpresa,
          documento: decode.documento,
          nomefantasia: decode.nomefantasia,
          usuario:{
            nome: decode.nome,
            email: decode.email,
            senha: decode.senha,
            nascimento: decode.nascimento,
            celular: decode.celular,
            
          },
          localizacao:{
            endereco: decode.endereco,
            cep: decode.cep,
            cidade: decode.cidade,
            uf: decode.uf,
            pais: decode.pais,
          },
          token: decode.token,
          datacadastro: decode.datacadastro
        }
        console.log('user',user)
        return user
      }else{
        return null;
      }
    }catch(err){
      console.log(err)
      return null
    }
  }else{
    return null
  }
}

export const deleteUser = () => {
  localStorage.removeItem(TOKEN_KEY);
};