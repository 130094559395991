import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import Lottie from 'react-lottie';

import loadingAnim from '../../assets/lottie/4278-loading.json'

export default function ModalLoading(props){

  const  loadingOptions = {
    loop: true,
    autoplay: true, 
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return(
    <Modal isOpen={props.isOpen}>
      <ModalBody>
        <Lottie options={loadingOptions} height={120} width={120} />
          <p style={{textAlign: "center"}}>{props.title}</p>
      </ModalBody>
    </Modal>
  );
}

ModalLoading.propsTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}