const en = {
  tituloprinfipal: "Behavioral profile mapping instrument through the identification of the PSYCHOLOGICAL TYPE",
  subtituloprinfipal: "PSYCHOLOGICAL TYPE",
  iniciarTeste: "START TEST",
  leiaMaisAqui: "Read more here",
  testesRealizados: "Tests taken",

  //Header
  inicio: "Home",
  fazeroteste: "Do the test",
  depoimentos: "Depositions",
  verDepoimentos: "See testimonials",
  deixarUmDepoimento: "Leave a testimonial",
  planos: "Plans",
  sobreNos: "About Us",
  contato: "Contact",
  sair: "Log out",
  entrar: "Log in",
  painel: "Panel",
  registreSe: "Register",

  //Fooder
  navegacao: 'Navigation',
  ajuda: "Help",
  todosOsDireitosReservados: 'All rights reserved',
  feitoComBastante: 'Made with enough',
  por: 'by',

  //Login
  senha: "password",
  naoTemConta: "Don't have an account?",
  esqueciSenha: "I forgot my password",

  //Registre-se
  criar: "create",
  jaTemConta: "Already have a registration?",


  //Pagina - Iniciar Teste
  vamosComecarSeuTeste: "Shall we start your test?",
  digiteSeuEmail: "Enter your email to get started",
  iniciar: "Start",
  gostouDoResultado: "Did you like the result?",

  // Quiz
  seuTipoPsic: " Your psychological type is",
  querSaberMais: "Want to know more? Click ",
  eSaibaTudo: " and know everything Persona Test has to say about you",
  percentual:"Percentage",

  //Pagina - Depoimentos
  data: "Date",
  seuEmail: "Enter your testimonial",
  enviandoDepoimento: "Sending testimonial. Please wait...",
  depoimentoEnviado: "Thank you for submitting your testimonial!",
  erroDepoimento: 'Sorry, an error occurred while sending your testimonial',
  tenteNovamente: "Try again",

  //Modal
  estaBem:  "It's ok",

  //Checkout - index
  checkout: "Checkout",
  tokenInvalido: "Invalid token. Please try again.",
  cupomInvalido: 'Invalid or inspired coupon code!',
  cupomAtivado: 'Coupon activated successfully!',
  digiteUmCupom: "Please enter a coupon code!",
  cupomJaAtivado: "You already activated this coupon!",
  cupomDesconto: "Discount coupon",
  irParaPagina: "Go to page",
  digiteSeuCupom: "Enter the code here",
  plano: "Plan",
  quantidade: "Amount",
  valor: "Value",
  resumo: "Resume",
  subtotal: "Subtotal",
  desconto: "Discount",
  total: "Total",
  concluirPedido: "Complete Order",

  //Checkout - sucesso
  eAgora: "And now?",
  sucessoP1: "Now that you know your PSYCHOLOGICAL TYPE, you have received an explanatory summary of the behavioral tendencies inherent to your way of feeling, acting and expressing yourself, PERSONATEST wants to invite you to continue to deepen your journey of self-knowledge!",
  sucessoP2: "We are preparing other plans with incredible content so that you can live an even more significant experience in the search for personal and professional growth.",
  sucessoP3: "It is important that you follow our communications by email, WhatsApp, Telegram and social networks to not miss any news",
  sucessoP4: "Join the Persona Test Community on Telegram and have access to numerous and exclusive information. Ask questions, participate with Professor Luiz Hosannah in discussions, group mentoring and much more. Exclusive for those who register on the site:",
  sucessoP5: "Follow the release of your reports on our portal, as soon as the payment platform confirms, the reports will be available for download",
  sucessoP6: "You can now access the portal by clicking",
  sucessoP7: "Ah, remember to leave your comment on the channels so that more people have the opportunity to get to know us, get to know each other and together with us work for an ever better world!",
  sucessoP8: "Man in suit and tie",
  sucessoP9: "Automatically generated description. Good journey!",
  aqui: "here",
  nossosCanais: "Subscribe to our channels:",

  //Checkout - pendente
  pendente: "Pending",
  pendenteP1: "Your purchase is in pending status. But don't worry, you'll soon be able to use your plan.",

  //Checkout - falha
  falha: "Something went wrong",
  falhaP1: 'Desculpa, não foi possivel finalizar sua compra. Tente novamente mais tarde ou entre em contato conosco.',

  //Página 404
  erro404: "404 - Page not found ",
  erro404P1: "This is just an unintended setback",
  erro404P2: " But we know that this is not the page you are looking for and we apologize for that. ",
  irPaginaInicial: "GO TO HOMEPAGE",

  //Página - Contato 
  entreEmContato: "Contact",
  enviar: "Submit",
  digiteAssunto: "Enter Subject",
  digiteSeuNome: "Type your name",
  inserirMensagem: "Type your message",
  rua: "Rua Senador Teotônio Vilela N. 110 - Sala 509 Ed. Cidadela Center II - Bairro Parque Bela Vista",
  estado: "Salvador - Bahia, CEP - 40279435",
  telefone: "+55 21 97340-8963",
  horarios: "Monday to Friday",
  emailContato: "contato@personatest.com.br",
  msgContato: "Send us an email at any time!",

  


  // Página - Planos
  carregando: "Loading...",
  otimaEscolha: "Good choice",
  paraContinuar: "To continue we need you to login or create a new one if you don't have an account.",
  
  fazerLogin: "Login",
  cancela: "Cancel",
  gratis: "Free",
  comprar: "Purchase",
  saibaMais: "Know more",
  cadastreSe: "Register",

  "Testes ilimitados": "Unlimited Tests",
  "Resultado na tela": "Result on screen",
  "Compartilhe nas redes sociais": "Share on social networks",
  
  "Relatório segmentado por área em pdf":"PDF segmented report",
  "e-book em pdf":"pdf e-book",
  "PAP":"PAP",
  "Plano de Aprimoramento Pessoal em pdf":"Personal Improvement Plan in pdf",
  "Livro Tipos Psicológicos (digital)":"Psychological Types Book (digital)",

  // Página - Sobre-nós
  p1: "Have you noticed how difficult it is to make decisions in the face of so many alternatives that life offers, whether in the loving, professional, educational, health promotion or relationship between friends and family? Do you have difficulty understanding who you are, being more tolerant of your limits and confident in your possibilities ?",
  p2: "PERSONA TEST is a behavioral profile mapping tool based on the theory of CG JUNG on PSYCHOLOGICAL TYPES and will help to overcome these obstacles, both from an individual and collective point of view (companies, academies, schools, clinics, etc.) .",
  p3: "According to C. G. Jung, there are four types of behavioral profile: THOUGHT, FEELING, SENSATION and INTUITION. All people have characteristics of the four, there is no better or worse, the ideal is the balance between them and each person has a superior type that determines their behavioral tendency.",
  p4: "The application of the PERSONA TEST results is comprehensive and capable of contributing to human development on an individual and collective level. In individual terms, it allows self-knowledge and identification of the main cognitive, emotional and behavioral competences that predominate in the persona. This is an essential strategic tool to map potentialities, recognize limitations, tolerate differences and increase the learning of new roles that allow the construction of a full life based on authenticity, freedom, autonomy and purpose.",
  p5: "In organizational terms, it is a unique tool, capable of providing subsidies for managers to promote job security, effectiveness in recruitment and selection, optimize the sales process, engage teams and train leaders. It also allows an educational institution or gym to be able to map the profile of students and train educators to use pedagogical techniques and other approaches compatible with the individual or collective typology, stimulating learning, loyalty and performance.",
  p6: "PERSONA TEST is the result of more than thirty years of work and research by Professor Luiz Hosannah in the area of development and human behavior and maps the typological profile of those who answer the test, explaining the proportion of each of the four types in the composition of their personality",
  p7: "Easy to understand, simple to speak and quick to execute, people over the age of fifteen will be able to identify their profile and benefit from this incredible tool of self-awareness. The final report is an excellent opportunity for self-knowledge and self-transformation, based on the identification of the very behavioral typological profile.",
  p8: "Precisely because of the countless possibilities of applying PERSONATEST results, you can choose between three reporting options:",
  p9: "In organizational terms, it is a unique tool, capable of providing subsidies for managers to promote job security, effectiveness in recruitment and selection, optimize the sales process, engage teams and train leaders. It also allows an educational institution or gym to be able to map the profile of students and train educators to use pedagogical techniques and other approaches compatible with the individual or collective typology, stimulating learning, loyalty and performance.",
  
  p10: "in this model you will know your PSYCHOLOGICAL TYPE and receive an explanatory summary of the behavioral tendencies inherent to your way of feeling, acting and expressing yourself. It's a good start!",
 
  p11: "However, if you recognize that it is necessary to go a step further, and your demand is to deepen the understanding of your psychodynamic structure, then an excellent option is the most advanced plan:",

  p12: "in addition to the content of the FREE model, you will have access to a much more advanced descriptive report that maps the way you relate to different dimensions of your daily life in the exercise of multiple personas:",
  p13: "How do you learn and communicate;",
  p14: "What is your leadership style and how does it contribute to group processes;",
  p15: "How do you get motivated in the face of challenges;",
  p16: "Quais cargos e funções que melhor se correlacionam com sua tipologia;",
  p17: "Passos iniciais para  equilibrar sua tipologia;",
  p18: "Tarefas organizacionais que podem gerar mais riscos de falhas operacionais, não conformidades e acidentes de trabalho;",
  p19: "Seu perfil de consumidor;",
  p20: "Seus pontos frágeis e necessidades de melhoria em termos comportamentais;",
  p21: "Como você tenta solucionar os problemas no cotidiano;",
  p22: "Como é seu comportamento nas relações amorosas;",
  p23: "Como você lida com a educação dos seus filhos.",

  p24: "O outro modelo é muito mais agregador e abrangente,  tem a missão de instrumentar sua jornada de  autotransformação.",

  p25: "esse modelo oferece todo conteúdo dos planos anteriores e inclui o PAP – PLANO DE APRIMORAMENTO PESSOAL E PROFISSIONAL. Esse plano de ação personalizado objetiva instrumentaliza-lo na viabilização do almejado  equilíbrio entre os 4 TIPOS PSICOLÓGICOS. É um roteiro com exercícios e um passo-a-passo detalhado, auto-explicativo,  que contribuirá para um salto qualitativo na construção de seu propósito e integração entre PERSONA e  SOMBRA.",

  p26: "Professor Hosannah graduou-se em 1988 na UFBa, é Mestre em História pela UFBa , especialista em educação, possui formação em Psicanálise e em Psicologia Analítica junguiana, pós-graduação em Psicologia Organizacional e em Psicologia Positiva, foi professor de graduação por mais de duas décadas e atualmente ministra cursos de pós-graduação no Brasil e exterior.",
  p27: "Psicoterapeuta há mais de 30 anos e palestrante nacional carismático, é autor de diversos artigos publicados e consultor de diversas organizações do porte da PETROBRÁS, BRASKEM, CORREIOS, FERBASA, TELEBRÁS, CHESF, PERINI, OAS, HOSPITAL SÃO RAFAEL, BAHIAGÁS, JUCEB, SEBRAE, SESI, FAFEN, CAIXA ECONÔMICA e centenas de outras em todo Brasil.",
  p28: "Viajante, já visitou quase 100 países e criou o projeto VIAJANDO COM PROFESSOR HOSANNAH no canal youtube.com/luizhosannah. Nesse projeto, ele grava vídeos diante de monumentos históricos e  simbólicos ao redor do mundo e correlaciona história, arte, mitologia e diversidade cultural com desenvolvimento humano, inteligência emocional e arquétipos.",
  p29: "Venha se beneficiar dessa ferramenta de tecnologia de ponta, rápida execução, alta fidedignidade e baseada em conhecimento validado cientificamente.",
  p30: "Boa jornada rumo ao autoconhecimento e à melhor versão de si mesmo em uma vida plena e com propósito.",

  p31: "Meus contatos",

  p32: "Testes realizados até o momento",
  p33: "Tem alguma pergunta?",


  // Traduçoes para o Dashboard

  // Header
  meuResultado: "My Result",
  meusRelatorios: "My Reports",

  //Youtube
  canalYoutube: "Youtube channel ",
  novosVideos: "New videos",

   //Meus Arquivos
   meusArquivos: "My Files",
   LIVRO_JUNG_BEM_ESTAR_CONFIABILIDADE_DIGITAL: "BOOK JUNG WELL BEING RELIABILITY",
   TIPOS_PSICOLÓGICOS: "PSYCHOLOGICAL TYPES",
   papEmPdf: "Personal Improvement Plan",
   baixarLivro: "Download Book",
   baixarEbook: "Download E-book",

  facaParteComnunidadeTelegram: "Join the Persona Test Community on Telegram",
  tenhaAcesso: "and have access to countless and exclusive information. Ask questions, participate in group discussions and mentoring and much more, with Professor Luiz Hosannah.",
  pagamentoPendete: "Your payment is being processed and once your report is confirmed it will be available here ...",
  baixarPDF: "Download your PDF",
  baixarPAP: "Download PAP",
  anterior: "Previous",
  proximo: "Next",
  
  tipoPsicologico: "Psychological Type",
  compartilhar: "To share",
  erroAoSalvar: "Houve um erro ao salvar. Por favor, tente novamente mais tarde.",
  qntTestesRealizados: "Tests",
  minhaConta: "My account",
  informacaoUsuario: "User Information",
  nomeUsuario: "Username",
  emailUsuario: "Email address",
  nascimentoUsuario: "Date of birth",
  contatoUsuario: "Contact information",
  telefoneUsuario: "Telephone",
  numeroTelefone: "Phone number",
  cidadeUsuario: "City",
  seuCidade: "Your city",
  estadoUsuario: "State",
  selecionarEstado: "Select",
  paisUsuario: "Country",
  seuPais: "Your country",
  alterarSenha: "Change Password",
  novaSenha: "New password",
  digiteNovaSenha: "Enter new password",
  cadastroAtualizado: "Registration updated",

  perfil: "My profile",
  relatorio: "Reporting",
  pap: "PAP",
  Dashboard: "Dashboard",
  email: "Email",
  mensagens: "Posts",
  bemVindo: "Welcome!"


}
export default en;
