import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios'
import md5 from 'md5'

import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CardPlanos from '../../components/Cards/CardPlanos'

import { isAuthenticated } from '../../services/auth'
import Context from '../../utils/context'
const isLogged = isAuthenticated();

const data = [
    {
      id: '1',
      title: "PLANO I",
      valor: "9.99",
      itens: [
        {
          enable: true,
          text: "Testes ilimitados"
        },
        {
          enable: true,
          text: "Compartilhe nas redes sociais"
        },
        {
          enable: true,
          text: "Relatórios Segmentados por Área"
        },
        {
          enable: false,
          text: "PAP - Plano de Aprimoramento Pessoal e Proficional"
        },
        {
          enable: false,
          text: "3 Mentorias com Prof. Luiz Hosannah"
        },
        {
          enable: false,
          text: "Vídeos Aulas pra Gestores, Lideres, Funcionários e Professores"
        },
        {
          enable: false,
          text: "Kit de Vídeo por Segmento"
        },
      ]
    },
    {
      id: '2',
      title: "PLANO II",
      valor: "39.99",
      itens: [
        {
          enable: true,
          text: "Testes Ilimitados"
        },
        {
          enable: true,
          text: "Compartilhe nas redess sociais"
        },
        {
          enable: true,
          text: "Relatórios Segmentados por Área"
        },
        {
          enable: true,
          text: "PAP - Plano de Aprimoramento Pessoal e Proficional"
        },
        {
          enable: false,
          text: "3 Mentorias com Prof. Luiz Hosannah"
        },
        {
          enable: false,
          text: "Vídeos Aulas pra Gestores, Lideres, Funcionários e Professores"
        },
        {
          enable: false,
          text: "Kit de Vídeo por Segmento"
        },
      ]
    },
    {
      id: '3',
      title: "PLANO III",
      valor: "79.99",
      itens: [
        {
          enable: true,
          text: "Testes Ilimitados"
        },
        {
          enable: true,
          text: "Compartilhe nas redes sociais"
        },
        {
          enable: true,
          text: "Relatórios Segmentados por Área"
        },
        {
          enable: true,
          text: "PAP - Plano de Aprimoramento Pessoal e Proficional"
        },
        {
          enable: true,
          text: "3 Mentorias com Prof. Luiz Hosannah"
        },
        {
          enable: false,
          text: "Vídeos Aulas pra Gestores, Lideres, Funcionários e Professores"
        },
        {
          enable: false,
          text: "Kit de Vídeo por Segmento"
        },
      ]
    },
    {
      id: '4',
      title: "PLANO IV",
      valor: "109.99",
      itens: [
        {
          enable: true,
          text: "Testes Ilimitados"
        },
        {
          enable: true,
          text: "Compartilhe nas redes sociais"
        },
        {
          enable: true,
          text: "Relatórios Segmentados por Área"
        },
        {
          enable: true,
          text: "PAP - Plano de Aprimoramento Pessoal e Proficional"
        },
        {
          enable: true,
          text: "3 Mentorias com Prof. Luiz Hosannah"
        },
        {
          enable: true,
          text: "Vídeos Aulas pra Gestores, Lideres, Funcionários e Professores"
        },
        {
          enable: true,
          text: "Kit de Vídeo por Segmento"
        },
      ]
    },
  ]

export default function Planos({history}){
  const { t, i18n } = useTranslation();
  const context = useContext(Context)
  const [ idSelected, setIdSelected] = useState(0)
  const [email, setEmail] = useState('');
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState('');
  const [planos, setPlanos] = useState(null)

  const toggle = () => setModal(!modal);

  useEffect(() => {
    console.log(isLogged)
    if(isLogged){
      console.log(context.getUser.usuario.email)
      setEmail(context.getUser.usuario.email)
    }
    async function getPlanos(){
      try {
        await axios.get('https://api-personatest.herokuapp.com/api/funtions/products?cnpj=true').then(res => {
          console.log(res.data)
          const { data } = res;
          setPlanos(data)
        })
      } catch (error) {
        
      }
    }
    getPlanos();
  }, [context.getUser.usuario.email])

  function hendleCheckout(id){
    console.log(id)
    switch(id){
      case 1:
      console.log('Free')
      break
      case 2:
      loadScreenCheckout(2)
      break
      case 3:
      loadScreenCheckout(3)
      break
      case 4:
      loadScreenCheckout(4)
      break
      case 5:
      loadScreenCheckout(5)
      break
      default:
      break
    }
  }

  function loadScreenCheckout(idIten){
    console.log(md5(idIten));
    const _token = md5(idIten);
    if(email){
      history.push(`/checkout?token=id${_token}&empresa=true&quantidade=10`)
    }else{
      history.push(`/login?callback=true&token=id${_token}&empresa=true&quantidade=10`)
      // setIdSelected(idIten)
      // toggle()
    }
  }

    
  return(
      <>
      <Header/>
      <div className="bradcam_area breadcam_bg_4_tipos" style={{
          marginTop: 80, 
          backgroundSize: "contain"
          }}>
      </div>
      <div className="package_prsing_area" style={{background: "#fff"}}>
          <div className="container">
            <div className="col-xl-12">
                <h1 className="contact-title" style={{textAlign: "center", marginBottom: 30, fontSize: 60, fontWeight: 600, }} >{t('planos')}</h1>
            </div>
            {planos !== null ? 
              <div className="row">
                {planos.map((item, key) => (
                    <CardPlanos
                    key={key.toString()}
                    title={item.nome}
                    descricao={item.descricao}
                    quantidadeMinima={item.quantidadeMinima}
                    valorAnterior={(item.valor).toFixed(2)}
                    valor={(item.valorPJ).toFixed(2)}
                    enable={item.ativo}
                    openCheckout={() => hendleCheckout(item.idProduto)}
                    />
                ))}
             </div>   
            : <h1>{t('carregando')}</h1>}
              
          </div>
      </div>
      <div>
          <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>{t('otimaEscolha')}</ModalHeader>
              <ModalBody>
              {t('paraContinuar')}
              </ModalBody>
              <ModalFooter>
              <Button color="primary" onClick={toggle}>{t('fazerLogin')}</Button>{' '}
              <Button color="secondary" onClick={toggle}>{t('cancelar')}</Button>
              </ModalFooter>
          </Modal>
      </div>
      <Footer/>
      </>
  );
};