import React, {Fragment} from 'react';
import Chart from "react-google-charts"
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Doughnut from '../Doughnut';
import Pie from '../Polar'
import 'chartjs-plugin-datalabels'

import {
    Container, Row, Col,
    Card, CardBody,
    CardTitle
} from 'reactstrap';

function ResultadoCharts(props){
    const { t, i18n } = useTranslation();
    const options = {
        tooltips: {
            enabled: false,
        },
        legend: {
            onClick: (e) => e.stopPropagation()
        },
        plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
                color: '#fff',
                font:{
                    size:16,
                   family: 'Arial',
                }
            }
        }
    }

    return(
        <>
           
            <div  >
          
               <Row>
                    <Col>
                        <Card >
                            <CardBody>
                                <CardTitle>{t('quantidade')}</CardTitle>
                                {props.completed ? <Doughnut  data={props.datasete[0]} options={options}/> : <span>{t('carregando')}</span>}
                            </CardBody>
                        </Card>
                    </Col>

                    <Col>
                        <Card >
                                <CardBody>
                                <CardTitle>{t('percentual')}</CardTitle>
                                 {props.completed ? <Pie data={props.datasete[1]} options={options}/>: <span>{t('carregando')}</span>}
                                </CardBody>

                        </Card >
                    </Col>
        
                </Row>
           
            </div>
       
        </>
    );

}

ResultadoCharts.Prototype = {
    completed: PropTypes.bool.isRequired,
    datasete: PropTypes.object.isRequired,
}

export default ResultadoCharts;
