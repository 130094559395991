import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import api from "../../services/api";

export default function Sobre() {
  const [totais, setTotais] = useState("");

  // function startTest(){
  //     console.log('...');
  //     history.push('/dashboard');
  // }
  useEffect(() => {
    async function loadTotais() {
      await api
        .get("statistic/tests")
        .then((res) => {
          setTotais(res.data[0].realizados);
        })
        .catch((error) => {});
    }
    loadTotais();
  }, []);

  return (
    <>
      <Header />
      <div
        className="bradcam_area"
        style={{ marginTop: 60, background: "#f7fafc" }}
      >
        <h3 style={{ color: "#525f7f" }}>Política de Privacidade</h3>
      </div>
      <div class="about_area" style={{ background: "#fff" }}>
        <div class="container" style={{ marginTop: 50 }}>
          <p>
            A VSE Tecnologia criou o aplicativo Persona Test como um aplicativo
            suportado por anúncios. Este SERVIÇO é fornecido pela VSE Tecnologia
            sem custo e destina-se ao uso como está.
          </p>{" "}
          <p>
            Esta página é usada para informar os visitantes sobre nossas
            políticas de coleta, uso e divulgação de Informações pessoais, se
            alguém decidir usar nosso Serviço.
          </p>{" "}
          <p>
            Se você optar por usar nosso Serviço, concorda em coletar e usar
            informações em relação a esta política. As informações pessoais que
            coletamos são usadas para fornecer e melhorar o serviço. não
            usaremos ou compartilharemos suas informações com ninguém, exceto
            conforme descrito nesta Política de Privacidade.
          </p>{" "}
          <p>
            Os termos usados ​​nesta Política de Privacidade têm o mesmo
            significado que em nossos Termos e Condições, que podem ser
            acessados ​​no Persona Test, a menos que definido de outra forma
            nesta Política de Privacidade.
          </p>{" "}
          <p>
            <strong>Coleta e uso de informações</strong>
          </p>{" "}
          <p>Coleta e uso de informações</p>{" "}
          <div>
            <p>
              O aplicativo usa serviços de terceiros que podem coletar
              informações usadas para identificá-lo.
            </p>{" "}
            <p>
              Link para a política de privacidade de provedores de serviços de
              terceiros usados ​​pelo aplicativo
            </p>{" "}
            <ul>
              <li>
                <a
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                >
                  Serviços do Google Play
                </a>
              </li>
              <li>
                <a
                  href="https://support.google.com/admob/answer/6128543?hl=en"
                  target="_blank"
                >
                  AdMob
                </a>
              </li>
              <li>
                <a
                  href="https://firebase.google.com/policies/analytics"
                  target="_blank"
                >
                  Google Analytics para Firebase
                </a>
              </li>
              <li>
                <a href="https://expo.io/privacy" target="_blank">
                  Expo
                </a>
              </li>
            </ul>
          </div>{" "}
          <p>
            <strong>Dados de log</strong>
          </p>{" "}
          <p>
            Nós queremos informá-lo de que sempre que você usa nosso Serviço, em
            caso de erro no aplicativo, coletamos dados e informações (por meio
            de produtos de terceiros) no seu telefone chamado Log Data. Esses
            dados de registro podem incluir informações como o endereço IP do
            dispositivo, nome do dispositivo, versão do sistema operacional, a
            configuração do aplicativo ao utilizar nosso serviço, a hora e a
            data de uso do serviço e outras estatísticas .
          </p>{" "}
          <p>
            <strong>Biscoitos</strong>
          </p>{" "}
          <p>
            Cookies são arquivos com uma pequena quantidade de dados que são
            comumente usados ​​como identificadores exclusivos anônimos. Eles
            são enviados para o navegador a partir dos sites visitados e
            armazenados na memória interna do dispositivo.
          </p>{" "}
          <p>
            Este serviço não usa esses "cookies" explicitamente. No entanto, o
            aplicativo pode usar código e bibliotecas de terceiros que usam
            "cookies" para coletar informações e melhorar seus serviços. Você
            tem a opção de aceitar ou recusar esses cookies e saber quando um
            cookie está sendo enviado para o seu dispositivo. Se você optar por
            recusar nossos cookies, poderá não conseguir usar algumas partes
            deste Serviço.
          </p>{" "}
          <p>
            <strong>Provedores de serviço</strong>
          </p>{" "}
          <p>
            podemos empregar empresas e indivíduos de terceiros devido aos
            seguintes motivos:
          </p>
          <ul>
            <li>Para facilitar nosso serviço;</li>
            <li>Fornecer o serviço em nosso nome;</li>
            <li>Executar serviços relacionados ao serviço; ou</li>
            <li>Para nos ajudar a analisar como nosso Serviço é usado.</li>
          </ul>
          <p>
            Nós queremos informar aos usuários deste serviço que esses terceiros
            têm acesso às suas informações pessoais. O motivo é executar as
            tarefas atribuídas a eles em nosso nome. No entanto, eles são
            obrigados a não divulgar ou usar as informações para qualquer outra
            finalidade.
          </p>{" "}
          <p>
            <strong>Segurança</strong>
          </p>{" "}
          <p>
            Valorizamos sua confiança em nos fornecer suas informações pessoais;
            portanto, estamos nos esforçando para usar meios comercialmente
            aceitáveis ​​de protegê-las. Mas lembre-se de que nenhum método de
            transmissão pela Internet ou método de armazenamento eletrônico é
            100% seguro e confiável, e não podemos garantir sua segurança
            absoluta.
          </p>{" "}
          <p>
            <strong>Links para outros sites</strong>
          </p>{" "}
          <p>
            Este serviço pode conter links para outros sites. Se você clicar em
            um link de terceiros, será direcionado para esse site. Observe que
            esses sites externos não são operados por nós. Portanto,
            recomendamos que você reveja a Política de Privacidade desses sites.
            não temos controle e não assumimos nenhuma responsabilidade pelo
            conteúdo, políticas de privacidade ou práticas de sites ou serviços
            de terceiros.
          </p>{" "}
          <p>
            <strong>Privacidade das crianças</strong>
          </p>{" "}
          <p>
            Esses serviços não tratam de menores de 13 anos. Não coletamos
            intencionalmente informações de identificação pessoal de crianças
            menores de 13 anos. No caso de descobrirmos que uma criança menor de
            13 anos nos forneceu informações pessoais, as excluiremos
            imediatamente de nossos servidores. Se você é pai ou mãe ou
            responsável e sabe que seu filho nos forneceu informações pessoais,
            entre em contato conosco para que possamos realizar as ações
            necessárias.
          </p>{" "}
          <p>
            <strong>Alterações a esta Política de Privacidade</strong>
          </p>{" "}
          <p>
            podemos atualizar nossa Política de Privacidade periodicamente.
            Portanto, é recomendável revisar esta página periodicamente para
            verificar se há alterações. iremos notificá-lo de quaisquer
            alterações publicando a nova Política de Privacidade nesta página.
          </p>{" "}
          <p>Esta política é eficaz a partir de 14/05 2020</p>{" "}
          <p>
            <strong>Contate-Nos</strong>
          </p>{" "}
          <p>
            Se você tiver alguma dúvida ou sugestão sobre nossa Política de
            Privacidade, não hesite em nos contactar em
            contato@personatest.com.br
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
