import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import api from '../../services/api'

export default function Sobre(){
    const { t, i18n } = useTranslation();
    const [ totais, setTotais ] = useState('');


    // function startTest(){
    //     console.log('...');
    //     history.push('/dashboard');
    // }
    useEffect(()=>{
        async function loadTotais(){
            await api.get('statistic/tests').then((res)=>{
                setTotais(res.data[0].realizados)
            }).catch((error)=>{
            });
        }
        loadTotais();
    },[]);

    return(
        <>
        <Header/>
        <div className="bradcam_area breadcam_bg_4_tipos" style={{
            marginTop: 80, 
            backgroundSize: "contain"
            }}>
        </div>
        <div className="about_area"  style={{background: "#fff", }}>
            <div className="container" style={{marginTop:10}}>
                <div className="col-xl-12">
                    <h1 className="contact-title" style={{textAlign: "center", marginBottom: 30, fontSize: 60, fontWeight: 600, }} >{t('sobreNos')}</h1>
                </div>
                <div className="row mb-60" style={{marginBottom: 5}}>
                    
                    <div className="col-xl-6">
                        <p>{t('p1')}</p>
                    </div>
                    <div className="col-xl-6">
                        <p>{t('p2')}</p>
                    </div>
                </div>
                <div className="section-top-border" style={{paddingTop:5, paddingBottom:5}}>
				    {/* <h3 className="mb-30">Block Quotes</h3> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <blockquote className="generic-blockquote">
                            {t('p3')}
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="sample-text-area"style={{paddingTop:5, paddingBottom:5}}>
            <div className="container box_1170">
                <p className="sample-text" >
                {t('p4')}
                </p>
                <p className="sample-text" >
                {t('p5')}
                </p>
                <p className="sample-text" >
                {t('p6')}
                </p>
                <p className="sample-text" >
                {t('p7')} 
                </p>
            </div>
        </section>
        <section className="sample-text-area"style={{paddingTop:5, paddingBottom:5}}>
            <div className="container box_1170">
                <p className="sample-text" >
                {t('p8')}
                </p>
                <p className="sample-text" >
                {t('p9')}
                </p>
                <ul>
                    <li>• FREE: {t('p10')} </li>
                </ul>
                <p className="sample-text" >
                    {t('p11')}
                </p>
                <ul>
                    <li>• START: {t('p12')}  </li>
                </ul>
                <ol>
                    <li>a)	{t('p13')}</li>
                    <li>b)	{t('p14')}</li>
                    <li>c)	{t('p15')}</li>
                    <li>d)	{t('p16')}</li>
                    <li>e)	{t('p17')}</li>
                    <li>f)	{t('p18')}</li>
                    <li>g)	{t('p19')}</li>
                    <li>h)	{t('p20')}</li>
                    <li>i)	{t('p21')}</li>
                    <li>j)	{t('p22')}</li>
                    <li>k)	{t('p23')}</li>
                </ol>
                <p className="sample-text" >
                    {t('p24')} 
                </p>
                <ul>
                    <li>• OFFICER: {t('p25')}  </li>
                </ul>
            </div>
        </section>
        <div className="about_area" style={{paddingBottom: 20, paddingTop: 20, background: "#fff"}}> 
            <div className="container">
                <div className="row mb-60" style={{marginBottom: 5}}>
                    <div className="col-xl-6">
                        <p>{t('p26')}</p>
                    </div>
                    <div className="col-xl-6">
                        <p>{t('p27')}</p>
                    </div>
                </div>
            </div>
        </div>
        <section className="sample-text-area"style={{paddingTop:5, paddingBottom:5}}>
            <div className="container box_1170">
                <p className="sample-text" >
                {t('p28')} 
                </p>
                <p className="sample-text" >
                {t('p29')}
                </p>
                <p className="sample-text" >
                    {t('p30')} 
                </p>
                <div className="socail_links">
                    <ul>
                        <li>
                            <p className="sample-text" >
                                {t('p31')}
                            </p>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/luiz.hosannah" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                                <i className="fa fa-facebook-square" style={{fontSize: 25}}></i>
                            </a>
                            <a href="https://www.youtube.com/luizhosannah" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                                <i className="fa fa-youtube" style={{fontSize: 25}}></i>
                            </a>
                            <a href="https://www.instagram.com/professorluizhosannah/" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                                <i className="fa fa-instagram" style={{fontSize: 25}}></i>
                            </a>
                            <a href="https://www.luizhosannah.com/" rel="noreferrer noopener" style={{padding: 10}} target="_blank" >
                                <i className="fa fa-globe" style={{fontSize: 25}}></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <div className="about_boxes" style={{paddingTop: 50, paddingBottom: 80,  background: "#fff"}}>
            <div className="container">
                <div className="row" style={{justifyContent: "center"}}>
                    <div className="col-xl-4 col-md-4">
                        <div className="single_box">
                            <h3>{totais}</h3>
                            <p>{t('p32')}</p>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-md-4">
                        <div className="single_box">
                            <h3>200</h3>
                            <p>Testes realizados até o momento</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="single_box">
                            <h3>563278</h3>
                            <p>Domain registation</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>

        <div className="have_question" style={{paddingTop: 50, paddingBottom: 100,  background: "#fff"}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 offset-xl-1">
                        <div className="single_border">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-md-4 col-lg-6">
                                    <h3 style={{color: "#000"}}>{t('p33')}</h3>
                                </div>
                                <div className="col-xl-6 col-md-8 col-lg-6">
                                    <div className="chat">
                                        <a className="boxed_btn_green" href={"https://wa.me/5521980900314?text=Olá,%20gostaria%20de%20obter%20mais%20informações%20sobre%20o%20Persona%20Test."} target="_black">
                                            <i className="flaticon-chat"></i>
                                            <span>Chat</span>
                                        </a>
                                        {/* <a class="boxed_btn_green2" href="#">
                                                get start now
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}