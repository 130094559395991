import React from 'react';
import PropTypes from 'prop-types';
import {Pie} from 'react-chartjs-2';

function PolarExample(props){
    
    return (
        <div >
             <Pie width={300} height={300} data={props.data} options={props.options}/>
        </div>
    )
}

PolarExample.propTypes = {
    data: PropTypes.object.isRequired,
}

export default PolarExample;