import React from 'react';
import PropTypes from 'prop-types';

export default function QuestaoCout(props) {

  return (
    <>
    <div className="progress" style={{height: 25+'px',  marginBottom: 20}}>
      <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:props.atual + '%'}} aria-valuenow={props.atual} aria-valuemin="0" aria-valuemax="100">{props.atual}%</div>
    </div>
    </>
  );
}

QuestaoCout.propTypes = {
    atual: PropTypes.number.isRequired,
};
