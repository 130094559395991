import React from "react";
import { registrePJ, login } from '../../services/auth';
import logo from    '../../assets/img/logo.png';
//import loginImg from "../../login.svg";

export class PessoaJuritica extends React.Component {
  constructor(props) {
    super(props);

    this.state = {nomepj: ''};
    this.state = {emailpj: ''};
    this.state = {senhapj: ''};
    this.state = {nomeFantasia: ''};
    this.state = {cnpj: ''};
    this.state = {responsavel: ''};
    this.state = {cpf: ''};
    this.state = {telefone: ''};
    this.state = {celular: ''};
    this.state = {endereco: ''};
    this.state = {cidade: ''};
    this.state = {uf: ''};
    this.state = {
      isChecked: false,
    };

   this.nomepj = this.nomepj.bind(this);
    this.emailpj = this.emailpj.bind(this);
    this.senhapj = this.senhapj.bind(this);
    this.nomeFantasia = this.nomeFantasia.bind(this);
    this.cnpj = this.cnpj.bind(this);
    this.responsavel = this.responsavel.bind(this);
    this.cpf = this.cpf.bind(this);
    this.telefone = this.telefone.bind(this);
    this.celular = this.celular.bind(this);
    this.endereco = this.endereco.bind(this);
    this.cidade = this.cidade.bind(this);
    this.uf = this.uf.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  emailpj(event) {
    this.setState({emailpj: event.target.value});
  }
  nomepj(event) {
    this.setState({nomepj: event.target.value});
  }
  senhapj(event) {
    this.setState({senhapj: event.target.value});
  }
  nomeFantasia(event) {
    this.setState({nomeFantasia: event.target.value});
  }
  cnpj(event) {
    this.setState({cnpj: event.target.value});
  }
  responsavel(event) {
    this.setState({responsavel: event.target.value});
  }
  cpf(event) {
    this.setState({cpf: event.target.value});
  }
  telefone(event) {
    this.setState({telefone: event.target.value});
  }
  celular(event) {
    this.setState({celular: event.target.value});
  }
  endereco(event) {
    this.setState({endereco: event.target.value});
  }
  cidade(event) {
    this.setState({cidade: event.target.value});
  }
  uf(event) {
    this.setState({uf: event.target.value});
  }
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }
 
  
  
  

  
	
  handleSubmit(event) {
    event.preventDefault();
    
    

    registrePJ(this.state.emailpj, this.state.nomepj, this.state.senhapj,this.state.nomeFantasia, this.state.cnpj, this.state.responsavel, this.state.cpf,this.state.telefone, this.state.celular, this.state.endereco, this.state.cidade, this.state.uf).then((res) =>{
      alert("Confirme seu email com o link que te enviamos. Verifica a sua caixa de Spam, se necessário.")
      // login(this.state.emailpj, this.state.senhapj).then((res) => {
      //   return window.location = '/login'
      // }).catch(res => {
      //   alert("Erro com a conexão com o Banco")
      // })
    }).catch(res => {
      alert("Erro com a conexão com o Banco")
    })
    
  }
  render() {
    return (
      <div className="base-container" ref={this.props.containerRef}>
        
      <br/><br/>
      <div className="header">Pessoa jurídica</div>
      <div className="content">
       
        <form onSubmit={this.handleSubmit}  className="form">
          <div className="form-group">
            <label htmlFor="username">Nome Completo</label>
            <input
            name="nome"
            type="text"
            placeholder="Nome Completo"
            value={this.state.nomepj} 
            onChange={this.nomepj}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
            name="email"
            type="email"
            placeholder="email"
            value={this.state.emailpj}
            onChange={this.emailpj}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Senha</label>
            <input
            name="senha"
            type="password"
            placeholder="A senha pode conter até 20 caracteres"
            value={this.state.senhapj}
            onChange={this.senhapj}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Nome Fantasia</label>
            <input
            name="nomeFantasia"
            type="text"
            placeholder="Nome Fantasia"
            value={this.state.nomeFantasia}
            onChange={this.nomeFantasia}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">CNPJ</label>
            <input
            name="cnpj"
            type="number"
            placeholder="CNPJ"
            value={this.state.cnpj}
            onChange={this.cnpj}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Responsavel</label>
            <input
            name="responsavel"
            type="text"
            placeholder="Responsavel"
            value={this.state.responsavel}
            onChange={this.responsavel}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">CPF</label>
            <input
            name="cpf"
            type="number"
            placeholder="cpf"
            value={this.state.cpf}
            onChange={this.cpf}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Telefone</label>
            <input
            name="telefone"
            type="number"
            placeholder="Telefone"
            value={this.state.telefone}
            onChange={this.telefone}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Celular</label>
            <input
            name="celular"
            type="number"
            placeholder="Celular"
            value={this.state.celular}
            onChange={this.celular}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Endereço</label>
            <input
            name="endereco"
            type="text"
            placeholder="Endereço"
            value={this.state.endereco}
            onChange={this.endereco}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Cidade</label>
            <input
            name="cidade"
            type="text"
            placeholder="Cidade"
            value={this.state.cidade}
            onChange={this.cidade}
            required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Uf</label>
            <input
            name="uf"
            type="text"
            placeholder="UF"
            value={this.state.uf}
            onChange={this.uf}
            required
            />
          </div>
          <div className="checkbox">
            <input name="Aenviar"
            type="checkbox"
            checked={this.state.isChecked}
          onChange={this.toggleChange}
            required/>
         &nbsp; <label>Autorizo enviar informações para este E-mail</label>
</div>   
          <div className="footer">
        <button type="submit" className="btn">
        Cadastrar
        </button>
      </div>
        </form>
      </div>
     
    </div>
    );
  }
}
