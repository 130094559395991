import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import 'chartjs-plugin-datalabels'

import Hearder from '../../components/Header';
import Footer from '../../components/Footer'

import Resultado from '../../components/Quiz/Resultado'
import {Event} from "../../components/Tracking";
import api from '../../services/api'

import { parseArrayMapResDet, createNewData } from '../../utils/Charts/ChatsFunctionUtils'

export default function ObterResultados(props){
    const [ email, setEmail ] = useState('');
    const [ result, setResult ] = useState('')
    const [ datasete, setDatasete ] = useState({});
    const [ titulo, setTitulo ] = useState('');
    const [ load, setLoad ] = useState(false);
    const [gettingEmail, setGettingEmail ] = useState(true);

    useEffect(()=>{
    },[]);

    // const newData = {
    //     labels: ["Um", "Dois"],
    //     datasets: [{
    //         data: [25,63],
    //         backgroundColor: ['#8dace7','#71deb9','#ef869e','#B4CEFF'],
    //         hoverBackgroundColor: ['#8dace7','#71deb9','#ef869e','#B4CEFF']
    //     }],
    // };

    // const options = {
    //     tooltips: {
    //         enabled: false,
    //     },
    //     legend: {
    //         onClick: (e) => e.stopPropagation()
    //     },
    //     plugins: {
    //         // Change options for ALL labels of THIS CHART
    //         datalabels: {
    //             color: '#fff',
    //             font:{
    //                 size:18,
    //             }
    //         }
    //     }
    // } 

    // -- Funções para buscar o resultato --
    function handleChangeEmail(event) {
        setEmail(event.target.value); // Salva no state email
    }
    
    function handleSubmitGetResultato(event) {
        event.preventDefault();
        setGettingEmail(false);
        resultado(email);
    }

    // 2º obter o id superior e id inferior
    async function resultado(email){
        await api.get(`funtions/eixos?email=${email}`).then((response)=>{
            const { idTipoSuperior, idTipoAuxiliar } = response.data[0];
            getEixosRespastas(idTipoSuperior,idTipoAuxiliar, email);
        }).catch((error)=>{
            console.log(error);
        })
    }

    async function getEixosRespastas(s,a, email){
        await api.get(`funtions/resumoeixo?idIdioma=1&idSuperior=${s}&idAuxiliar=${a}&origem=N`).then((res)=>{
            console.log('eixos: ',res.data);
            const { data } = res;
            resultadoDetalhado(email);
            setTitulo(data.Eixo);
            setResult(data.descricao); 
        }).catch((error)=>{
            console.log(error.message);
            console.log(error.response);
        })
    }

    async function resultadoDetalhado(email){
        await api.get(`funtions/respostas?email=${email}&detalhe=true`).then((res)=>{
            console.log(res.data)
            const _data = parseArrayMapResDet(res.data);
            console.log(_data)
            const _vq = createNewData(_data.tipo,_data.qttipo);
            const _vp = createNewData(_data.tipo,_data.percentual);
            const new_data = [_vq,_vp]
            console.log(new_data)
            setDatasete(new_data);
            setLoad(true)
        }).catch((error)=>{
            console.log(error);
        });
    }

    // -- Fim

    // -- Formulário de com email e buntão iniciar
    function getEmail(){
        return(
            <>
            { gettingEmail ? 
                <div className="slider_area">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-9">
                                <div className="slider_text text-center">
                                    <p>Obtenha seu resultado aqui</p>
                                    <h1 style={{color: "#fff"}}>Digite seu email</h1><br></br>
                                    <div className="find_dowmain">
                                        <form onSubmit={handleSubmitGetResultato} className="find_dowmain_form">
                                            <input 
                                            className="form-control valid" 
                                            name="email" 
                                            id="email" 
                                            type="email" 
                                            placeholder="Email" 
                                            autoComplete="username" 
                                            value={email} 
                                            onChange={handleChangeEmail} 
                                            required/>
                                            <button type="submit">Mostre-me</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <div>
                    <h1>Carregando...</h1>
                </div>
            }            

            </>
        );
    }
    // -- Fim formulário


    function renderResult() {
        Event('BUSCA', 'Resultado', `${titulo}`, true);
        return <Resultado titulo={titulo} texto={result} datasete={datasete} completed={true}/>;
    }


    return(
        <>
        <Hearder/>
            <div className="bradcam_area breadcam_bg overlay2">
                <CSSTransition
                    className="container"
                    timeout={500}
                    component="div"
                    transitionName="fade"
                    transitionEnterTimeout={800}
                    transitionLeaveTimeout={500}
                    transitionAppear
                    transitionAppearTimeout={500}
                >
                    { load ?  renderResult() : getEmail() }

                </CSSTransition>
            </div>
        <Footer/>
        </>
    );
}