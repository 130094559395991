import React from 'react';
import PropTypes from 'prop-types';


export default function Respostas(props) {
  return (
    <>
    <li className="unordered-list">
        <label className="radioCustomLabel" htmlFor={props.answerType}>
          <div className="list-resposta">
            <input
            type="radio"
            className="radioCustomButton"
            name="radioGroup"
            //checked={props.answerType === props.answer}
            id={props.answerType}
            value={props.answerType}
            disabled={props.answer}
            onChange={props.onAnswerSelected}
          />
            {' '+props.answerContent}
        </div>
        </label>
    </li>
    </>
  );
}

Respostas.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired
};
