import React, { useState} from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import api from '../../services/api'

export default function RecuperarSenha(){

    const [ email, setEmail ] = useState('');
    const [ msg, setMsg ] = useState('');
    const [ sucess, setSucess ] = useState(false);
    const [ msgErr, setMsgErr ] = useState('');


    async function handleSubmit (event) {
      event.preventDefault();

      if(!email){
        return console.log('Email faltando');
      }
      setMsg('Enviando email. Por favor, aguarde...')
      setMsgErr('');
      await api.post('user/recoverPassword', {email}).then(res => {
        console.log(res)
        setSucess(true)
        setMsg('')
      }).catch(err => {
        setMsg('');
        setMsgErr('E-Mail não encontrado')
        console.log(err)
      })
    
    }
    

    return(
        <>
        <Header/>
        <div className="bradcam_area" style={{marginTop: 60, background: "#f7fafc"}}>
            <h3 style={{color: "#525f7f"}}>RECUPERAR SENHA</h3>
        </div>
         <section className="contact-section" style={{ background: "#FFF"}}>
            <div className="container">
            {sucess === !true ?
                <div className="row">
                    <div className="col-12">
                        <h2 className="contact-title" >Digite seu email</h2>
                    </div>
                    <div className="col-lg-8">
                      {msg !== '' ? <p>Enviando email. Por favor, aguarde...</p> :
                          <form onSubmit={handleSubmit} className="form-contact contact_form" >
                             <p>{msgErr}</p>
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-group">
                                          <input onChange={e => setEmail(e.target.value)} value={email} className="form-control" type="email" required={true} placeholder="Email" ></input>
                                      </div>
                                  </div>
                              </div>
                              
                              <div className="form-group mt-3">
                                  <button type="submit" className="button button-contactForm boxed-btn">Enviar</button>
                              </div>
                        </form>
                        }
                    </div>
                </div>:
                 <div className="row">
                    <div className="col-12">
                        <h2 className="contact-title" >Por favor, confira seu email.</h2>
                    </div>
                  </div>
                }
            </div>
        </section>
        <Footer/>
        </>
    );
}