import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

//https://api-personatest.herokuapp.com/api/ production
//https://aqueous-reaches-04861.herokuapp.com/api/ development
//http://localhost:3333/api/ local
//http://186.233.148.102:5432/ server
//http://179.210.161.186:5432/ marcelino

export default api;