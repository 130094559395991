import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import {
  connect,
  disconnect,
  subcribeCounterTest,
} from "../../services/socket";
import axios from "axios";
import ModalImage from "react-modal-image";
import CardDepoimento from "../../components/Cards/CardDepoimento";
import ModalInfo from "../../components/Modals/ModalInfo";

const urlCMS = "https://cms.personatest.com.br";

export default function Slider({ go }) {
  const { t, i18n } = useTranslation();
  const [totais, setTotais] = useState(0);
  const [estado, setEstado] = useState("");
  const [depoimento, setDepoimento] = useState({
    nome: "",
    texto: "",
    data: "",
  });
  // const [ imagens, setImagens ] = useState(null)
  const [titulo, setTitulo] = useState("");
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  // function startTest(){
  //     console.log('...');
  //     history.push('/dashboard');
  // }
  useEffect(() => {
    async function loadTotais() {
      await api
        .get("statistic/tests")
        .then((res) => {
          const { realizados } = res.data[0];
          setTotais(parseInt(realizados));
        })
        .catch((error) => {
          console.log(error.message);
          console.log(error.response);
        });
    }
    loadTotais();
    getComentarios();
    setupWebsocket();
  }, []);

  useEffect(() => {
    subcribeCounterTest((text) => {
      setTotais((prevValue) => prevValue + 1);
      setEstado(text);
    });
  }, []);

  async function getComentarios() {
    await axios
      .post(
        `https://cms.personatest.com.br/api/collections/get/comentarios?token=e1fca3527f0615d1a4c66017ebb84e`,
        {
          filter: {
            publicado: true,
          },
          fields: {
            publicado: 1,
            nome: 1,
            texto: 1,
            data: 1,
          },
          limit: 1,
          sort: {
            _created: -1,
          },
        }
      )
      .then((res) => {
        const { entries } = res.data;
        console.log(entries);
        setDepoimento(entries[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClick(e) {
    e.preventDefault();
    console.log("The link was clicked.");
    toggleModal();
  }

  // async function getDestaque(){
  //     await axios.post(`https://cms.personatest.com.br/api/collections/get/destaque?token=e1fca3527f0615d1a4c66017ebb84e`)
  //     .then(res => {

  //         const { entries } = res.data
  //         console.log(entries)
  //         const _imgs = [
  //             `${urlCMS}${entries[0].imagem_pequena.path}`,
  //             `${urlCMS}${entries[0].imagem_grande.path}`
  //         ]
  //         setTitulo(entries[0].titulo)
  //         setImagens(_imgs)

  //     }).catch(err => {
  //         console.log(err)
  //     })
  // }

  function setupWebsocket() {
    disconnect();
    connect();
  }

  return (
    <div className="slider_area">
      <div
        className="single_slider d-flex align-items-center justify-content-center slider_bg_1 overlay2"
        style={{ marginTop: 70, paddingTop: 70 }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-12 mt-1">
              <div className="slider_text text-center">
                <h3 style={{ marginBottom: 0 }}>{t("tituloprinfipal")}</h3>
                <h3 style={{ marginBottom: 0 }} className="text-white">
                  {t("subtituloprinfipal")}
                </h3>
                <div className="button_start" style={{ padding: 0 }}>
                  <button type="submit" onClick={go}>
                    {t("iniciarTeste")}
                  </button>
                </div>
                {depoimento ? (
                  <div className="col-xl-12">
                    {depoimento.texto && (
                      <h4 className="sample-text" style={{ color: "#fff" }}>
                        <span style={{ fontSize: 30 }}>&quot;</span>
                        {depoimento.texto.slice(0, 50)}...
                        <span style={{ fontSize: 30 }}>&quot; </span>
                        <br></br>
                        <a href="#" onClick={handleClick}>
                          <span style={{ color: "#FFEB3B" }}>
                            {t("leiaMaisAqui")}
                          </span>
                        </a>
                      </h4>
                    )}
                    <br></br>
                    <h4 className="sample-text" style={{ color: "#fff" }}>
                      {depoimento.nome}
                    </h4>
                  </div>
                ) : null}
                <div className="stats-wrapper" style={{ marginTop: 0 }}>
                  <div className="stats">
                    {totais !== 0 ? (
                      <div className="counter" style={{ marginBottom: 0 }}>
                        {totais}
                      </div>
                    ) : (
                      <span>{t("carregando")}</span>
                    )}
                    <div className="text">{t("testesRealizados")}</div>
                    {estado && (
                      <h4 style={{ color: "#fff" }}>
                        Opa, alguém de{" "}
                        <span style={{ color: "#FFEB3B" }}>{estado}</span> fez
                        um teste!
                      </h4>
                    )}
                  </div>
                </div>

                {/* {imagens ? 
                            <ModalImage
                                small={imagens[0]}
                                large={imagens[1]}
                                alt={titulo}
                                imageBackgroundColor="#ffffff00"
                            /> :
                            null
                            } */}
                {/* <img style={{width: 450, higth: 450}} src={depoimento} alt="depoimento de destaque"></img>
                 */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalInfo
        isOpen={showModal}
        toggle={toggleModal}
        handleButton={toggleModal}
        lebalButton="Fechar"
      >
        <CardDepoimento
          ativo={true}
          texto={depoimento.texto}
          nome={depoimento.nome}
          data={depoimento.data}
        />
      </ModalInfo>
    </div>
  );
}
