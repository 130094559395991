import React from 'react';
import Router from './router';
import Context from './utils/context'

import { isAuthenticated, getToken, login, logout } from './services/auth'
import { getUser } from './services/user'
import { createPurchase } from './services/purchase'

export default function ContextState(){

    return(
        <div>
            <Context.Provider value={{

                //Auth
                isAuthenticated: () => isAuthenticated(),
                getToken: () => getToken(),
                login: (email, pass) => login(email, pass),
                logout: () => logout(),
                getUser: getUser(),
                createPurchase: (username, email) => createPurchase(username, email)
            }}>
                <Router/>
            </Context.Provider>
        </div>
    );
}