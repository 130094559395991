import api from "./api";
import md5 from "md5";
import { setUser } from "./user";
import JwtDecode from "jwt-decode";
const TOKEN_KEY = "@perstest-Token";

export const isAuthenticated = () => {
  if (localStorage.getItem(TOKEN_KEY) !== null) {
    const token = getToken();
    try {
      const decode = JwtDecode(token);
      console.log("decode", decode);
      if (decode.exp * 1000 > Date.now()) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  } else {
    return false;
  }
};
export const getToken = () => {
  const tokenKey = localStorage.getItem(TOKEN_KEY);
  console.log(tokenKey);
  return tokenKey;
};
const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const deleteToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const login = (email, senha) =>
  new Promise((resolve, reject) => {
    if (!email || !senha) {
      reject("Preencha e-mail e senha para continuar!");
    } else {
      try {
        api
          .post("user/session", { email, senha })
          .then((res) => {
            const { data } = res;
            if (data.token) {
              setToken(data.token);
              return resolve("sucess");
            } else {
              return reject(
                "Confirme seu email com o link que te enviamos. Verifica a sua caixa de Spam, se necessário."
              );
            }
          })
          .catch((err) => {
            const { data } = err.response;
            if (data.status === 401) {
              return reject(data.errors.mensagem);
            }
            return reject(
              "Erro interno no servidor. Por favor, tente novamente"
            );
          });
      } catch (error) {
        reject("Houve um problema com o logou, tente novamente");
      }
    }
  });

export const registre = (nome, email, senha) =>
  new Promise((resolve, reject) => {
    try {
      api
        .post("/user/create", { nome, email, senha })
        .then((res) => {
          const data = res.data;
          return resolve("sucess");
        })
        .catch((err) => {
          const { data } = err.response;
          if (data.status === 401) {
            return reject(data.errors.message);
          }
          return reject("Erro interno no servidor. Por favor, tente novamente");
        });
    } catch (err) {
      reject(
        "Houve um erro ao realizar o registrar, por favor tente novamente mais tarde!"
      );
    }
  });

export const registrePJ = (
  email,
  nome,
  senha,
  nomeFantasia,
  cnpj,
  responsavel,
  cpf,
  telefone,
  celular,
  endereco,
  cidade,
  uf
) =>
  new Promise((resolve, reject) => {
    try {
      api
        .post("/user/createpj", {
          email,
          nome,
          senha,
          nomeFantasia,
          cnpj,
          responsavel,
          cpf,
          telefone,
          celular,
          endereco,
          cidade,
          uf,
        })
        .then((res) => {
          const data = res.data;
          // login(email, senha)
          return resolve("Parabéns! Seu cadastro foi realizado com sucesso");
        })
        .catch((err) => {
          const { data } = err.response;
          if (data.status === 401) {
            return resolve(data.errors.message);
          }
          return resolve(
            "Erro interno no servidor. Por favor, tente novamente"
          );
        });
    } catch (err) {
      resolve(
        "Houve um erro ao realizar o registrar, por favor tente novamente mais tarde!"
      );
    }
  });

export const logout = () =>
  new Promise((resolve, reject) => {
    try {
      deleteToken();
      resolve("Logout sucess!");
    } catch (error) {
      reject("Houve um problema ao sair, tente novamente");
    }
  });
