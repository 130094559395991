import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

import Header from "../components/Headers/Header";

export default function Painel(){
  const { t, i18n } = useTranslation();
  return(
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ztEB4Fim7c4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            {/* <video controls class="embed-responsive-item">
              <source src="http://personatest.com.br/static/media/videos/videopersona001.mp4" type="video/mp4"></source>
            </video> */}
          </div>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      {t('canalYoutube')}
                    </h6>
                    <h2 className="mb-0">{t('novosVideos')}</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart" style={{height: 'auto'}}>
                  <div class="embed-responsive embed-responsive-16by9" style={{marginBottom: 20}}>
                    <iframe 
                      title="Plano FREE"
                      class="embed-responsive-item" 
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                      frameborder="0" 
                      allowfullscreen
                      src="https://www.youtube.com/embed/dc7y7a5Qtm0" >
                    </iframe>
                  </div>
                </div>
                <div className="chart"  style={{height: 'auto'}}>
                  <div class="embed-responsive embed-responsive-16by9" style={{marginBottom: 20}}>
                    <iframe 
                      title="Plano START"
                      class="embed-responsive-item" 
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                      frameborder="0" 
                      allowfullscreen
                      src="https://www.youtube.com/embed/s8O2orsu7D0" >
                    </iframe>
                  </div>
                </div>
                <div className="chart" style={{height: 'auto'}}>
                  <div class="embed-responsive embed-responsive-16by9" style={{marginBottom: 20}}>
                    <iframe 
                      title="Plano OFFICER"
                      class="embed-responsive-item" 
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                      frameborder="0" 
                      allowfullscreen
                      src="https://www.youtube.com/embed/YE4AN-U8xnc" >
                    </iframe>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}