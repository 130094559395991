import React from 'react';
import PropTypes from 'prop-types';
import {Doughnut} from 'react-chartjs-2';

function DoughnutExample(props){

    return (
        <div>
            <Doughnut width={300} height={300} data={props.data} options={props.options}/>
        </div>
    )
}

DoughnutExample.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
}

export default DoughnutExample;