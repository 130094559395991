import React from "react";
import { useTranslation } from "react-i18next";
import { EventLink } from "../../components/Tracking";
import LogoIconeAcima from "../../assets/img/img_site/LogoIconeAcima.png";
export default function Footer() {
  const { t, i18n } = useTranslation();
  function toFacebook(e) {
    e.preventDefault();
    EventLink("SOCIAL", "Facebook", "Página");
    window.open("https://www.facebook.com/personatest.oficial/", "_blank");
  }
  function toYoutube(e) {
    e.preventDefault();
    EventLink("SOCIAL", "YouTube", "Perfil");
    window.open("https://www.youtube.com/luizhosannah", "_blank");
  }
  function toInstagram(e) {
    e.preventDefault();
    EventLink("SOCIAL", "Instagram", "Perfil");
    window.open("https://www.instagram.com/personatest.oficial/", "_blank");
  }

  return (
    <footer className="footer">
      <div className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-6 col-lg-4">
              <div className="footer_widget">
                <div className="footer_logo">
                  <a href="/">
                    <img src={LogoIconeAcima} alt="" width={150} />
                  </a>
                </div>
                <div className="socail_links">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/luiz.hosannah"
                        rel="noreferrer noopener"
                        target="_blank"
                        onClick={toFacebook}
                      >
                        <i className="fa fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/luizhosannah"
                        rel="noreferrer noopener"
                        target="_blank"
                        onClick={toYoutube}
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/professorluizhosannah"
                        rel="noreferrer noopener"
                        target="_blank"
                        onClick={toInstagram}
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-lg-2">
              <div className="footer_widget">
                <h3>{t("navegacao")}</h3>
                <ul>
                  <li>
                    <a href="/">{t("inicio")}</a>
                  </li>
                  <li>
                    <a href="/inicia-teste">{t("fazeroteste")}</a>
                  </li>
                  <li>
                    <a href="/contato">{t("contato")}</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-xl-2 col-md-6 col-lg-2">
                        <div className="footer_widget">
                            <h3 className="footer_title">
                                Legal
                            </h3>
                            <ul>
                                <li><a href="/">Termos e Condições</a></li>
                                <li><a href="/">Política de Privacidade</a></li>
                            </ul>
                            

                        </div>
                    </div>
                    {/* <div className="col-xl-2 col-md-6 col-lg-2">
                        <div className="footer_widget">
                            <h3 className="footer_title">
                                Newsletter
                            </h3>
                            <form action="#" className="newsletter_form">
                            <input 
                                id="user_email" 
                                placeholder="Seu E-MAIL"
                                type="text"
                                />
                                <button className="btn" type="submit">Sign Up</button>
                            </form>
                            <p className="newsletter_text">Subscribe newsletter to get updates</p>
                        </div>
                    </div> */}
            <div className="col-xl-2 col-md-6 col-lg-2">
              <div className="footer_widget">
                <h3>{t("ajuda")}</h3>
                <ul>
                  <li>
                    <a href="/sobre-nos">{t("sobreNos")}</a>
                  </li>
                  <li>
                    <a href="/politica-de-privacidade">{t("Politica")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right_text">
        <div className="container">
          <div className="footer_border"></div>
          <div className="row">
            <div className="col-xl-12">
              <p className="copy_right text-center">
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script> 2020
                - {t("todosOsDireitosReservados")} | {t("feitoComBastante")}{" "}
                <i className="fa fa-coffee" aria-hidden="true"></i> {t("por")}{" "}
                <a
                  href="https://f4cil.com.br/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  F4cil Soluções
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
