import React, {useEffect, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Context from '../../utils/context'
import ModalInfo from '../../components/Modals/ModalInfo'
import api from  '../../services/api';
import axios from 'axios';
import md5 from 'md5'
import { Progress, CardColumns, Table, Button, Form, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import CardDepoimento from '../../components/Cards/CardDepoimento'

import './styles.css'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useLocation } from 'react-router-dom';

// const data = [
//   {
//     id: 'id93b885adfe0da089cdf634904fd59f71',
//     title: "PLANO I",
//     valor: "9.99",
//   },
//   {
//     id: 'id55a54008ad1ba589aa210d2629c1df41',
//     title: "PLANO II",
//     valor: "39.99",
//   },
//   {
//     id: 'id9e688c58a5487b8eaf69c9e1005ad0bf',
//     title: "PLANO III",
//     valor: "79.99",
//   },
//   {
//     id: 'id8666683506aacd900bbd5a74ac4edf68',
//     title: "PLANO IV",
//     valor: "109.99",
//   },
// ]

export default function Checkout({history}){
  const context = useContext(Context);
  const { t, i18n } = useTranslation();
  const [ depoimentos, setDepoimentos ] = useState(null)
  const [ isValidToken, setIsValidToekn ] = useState(false)
  const [ modalInfo, setModalInfo ] = useState(false)
  const [ modalInfoToken, setModalInfoToken ] = useState(false)
  const [ idCupom, setIdCupom ] = useState(null);
  const [ hasCupom, setHasCupom ] = useState(false)
  const [ isEmpresa, setIsEmpresa ] = useState(false)
  const [ erroMensagem, setErroMensagem] = useState('');
  const [ percentCompleted, setPercentCompleted ] = useState(0);
  const [ cupom, setCupom ] = useState('')
  const [ cartInfo, setCardInfo ] = useState({
    id_product: '', 
    title: '', 
  })
  const [ lista, setLista ] = useState([])
  const [resumo, setResumo] = useState({
    subtotal: 0,
    total: 0
  });
  const [ desconto, setDesconto ] = useState(0)

  const handleModal = () => setModalInfo(!modalInfo);
  const handleModalToken = () => setModalInfoToken(!modalInfoToken);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const _token = query.get('token');
  const _empresa = query.get('empresa');
  const _quantidade = query.get('quantidade');

  const config = {
    onDownloadProgress: function (progressEvent) {
      var percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercentCompleted(percent);
      console.log(percentCompleted)
    }
  }

  useEffect(() => {
   
    let isEmpresa = ''
    if(_empresa === 'true'){
      isEmpresa = '?cnpj=true'
      setIsEmpresa(true)
    }
    async function getPlanos(){
      try {
        await api.get(`funtions/products${isEmpresa}`, config).then(res => {
          const { data } = res;
          data.map((item) => {
            let idToken = 'id'+md5(item.idProduto);
            const regex = /%%/gi;
            console.log(idToken)
            if(item.ativo === true){
              if(idToken === _token){
                setIsValidToekn(true);
                let desc = item.descricao;
                let quantidade = 1
                let valor;
                if(isEmpresa){
                  quantidade = item.quantidadeMinima
                  console.log(item.valorPJ)
                  valor = parseFloat(item.valorPJ).toFixed(2)
                }else{
                  valor = parseFloat(item.valor).toFixed(2)
                }
                desc = desc.replace(regex, ' ');
                setCardInfo({
                  id_product: item.idProduto, 
                  title: item.nome, 
                  quantidade,
                  desc
                })
                splitLi(item.descricao)
                console.log(item.valor)
                const subtotal = parseFloat(quantidade * valor).toFixed(2)
                const desconto = 0;
                const total = parseFloat(subtotal - desconto).toFixed(2)
                const data = {
                  valorUni: valor,
                  subtotal,
                  total
                }
                console.log(data)
                setResumo(data)
              }
            }
          });
          // hendlePurchase( id_user, id_product, email, title, amount, valid)
        })
      } catch (err) {
        console.error(err)
      }
    }
    getPlanos();
    getComentarios();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function splitLi(string){
    let res = string.split('%%');
    setLista(res)
  }

  async function getDiscont(e){
    e.preventDefault();
    console.log('getDiscont', resumo, cupom)
    
    if(hasCupom === false){
      if(cupom){
        await api.post('purchase/discount', { 
          cupom: cupom, 
          idProduto: cartInfo.id_product, 
          subtotal: resumo.subtotal, 
        })
        .then(res => {
          const { data } = res;
          console.log('Cumpom Info: ', data)
          if(data.Codigo === "INVALIDO"){
            console.error("INVALIDO");
            setErroMensagem(t('cupomInvalido'));
            handleModal();
          }else{
            setIdCupom(data.idCupomDesconto)
            setDesconto(data.desconto)
            calculateSummary(1, data.desconto)
            setHasCupom(true);
            setErroMensagem(t('cupomAtivado'));
            handleModal();
          }
        })
      }else{
        setErroMensagem(t('digiteUmCupom'));
        handleModal();
      }
    }else{
      console.error("CUPOM JÁ ATIVADO");
      handleModal()
      setErroMensagem(t('cupomJaAtivado'))
    }
    
  }

  async function hendlePurchase(){
    console.log(context.getUser.idPessoa, cartInfo.id_product, context.getUser.usuario.email, cartInfo.title, cartInfo.desc, resumo.total, idCupom);
    const data_compra = {
      id_product: cartInfo.id_product, 
      id_cupom: idCupom, 
      email: context.getUser.usuario.email, 
      id_user: context.getUser.idPessoa,
      quantidade: cartInfo.quantidade
    }
    if(resumo.total === "0.00"){
      console.log('Enviadno compra...')
      try {
        await api.post('notifications/gravaCompra', data_compra)
        .then(res => {
          console.log(res.data)
          history.push('/compra-finalizada');
        })
        .catch( err => {
          console.error(err)
          handleModal()
          setErroMensagem(t('erroAoEnviar'))
        })
      } catch (error) {
        console.error(error)
        handleModal()
        setErroMensagem(t('erroAoEnviar'))
      }
    }else{
      window.location = `https://api-vse-payments.herokuapp.com/api/mercadopago/checkout/${context.getUser.idPessoa}/${context.getUser.usuario.email}/${cartInfo.id_product}/${cartInfo.title}/${cartInfo.desc}/${cartInfo.quantidade}/${resumo.valorUni}/${idCupom}?platform=web`;
    }
   
  }

  async function getComentarios(){
    await axios.post(`https://cms.personatest.com.br/api/collections/get/comentarios?token=e1fca3527f0615d1a4c66017ebb84e`,
    {
      filter: {
        publicado: true
      },
      fields: {
          publicado: 1, nome: 1, texto: 1, data: 1
        },
      sort: {
        _created: -1
      },
      limit: 6
    })
    .then(res => {
        const { entries } = res.data
        console.log(entries)
        setDepoimentos(entries)
    }).catch(err => {
        console.log(err)
    })
  }

  function changeQuant(type, value){
    console.log('Quantidade', cartInfo.quantidade)
    let quantidade;
    if(type === '-'){
      if(cartInfo.quantidade === 10 && _empresa === 'true'){
        console.log('Quantidade minita de 10')
      }else if(cartInfo.quantidade === 1){
        console.log('Quantidade minita de 1')
      }else{
        quantidade = cartInfo.quantidade - 1;
        calculateSummary(quantidade, desconto)
      }
    }else if(type === '+'){
      quantidade = cartInfo.quantidade + 1;
      calculateSummary(quantidade, desconto)
    }else{
      if(cartInfo.quantidade === 10 && _empresa === 'true'){
        console.log('Quantidade minita de 10')
      }else if(cartInfo.quantidade === 1){
        console.log('Quantidade minita de 1')
      }else{
        quantidade = parseFloat(value);
        calculateSummary(quantidade, desconto)
      }
    }
  }

  function calculateSummary (amount, discont ) {

    console.log('resumo', resumo)
    const _subtotal = parseFloat(resumo.valorUni * amount).toFixed(2);
    const _discont = discont / 100;
    const discontValue = Number.parseFloat(_subtotal * _discont).toFixed(2);
    const newValue = _subtotal - discontValue;
    const _total = Number.parseFloat(newValue).toFixed(2);
    console.log({_subtotal, _discont, discontValue, newValue, _total})
    const discontInfo = {
      _subtotal,
      desconto: discontValue,
      _total
    }
    console.log(discontInfo)
    setCardInfo({...cartInfo, quantidade: amount})
    setResumo({...resumo, subtotal: _subtotal, total: _total })
  }

  return(
    <>
    <Header/>
    <div className="bradcam_area breadcam_bg overlay2">
        <h3>{t('checkout')}</h3>
    </div>
    <section className="contact-section" style={{ background: "#FFF"}}>
      <div className="container">
      {/* <div className="col-xl-12">
          <h1 className="contact-title" style={{textAlign: "center", marginBottom: 30, fontSize: 60, fontWeight: 600, }} >Contato</h1>
      </div> */}
        <div className="row">
          <div className="col-12">
            <h2 className="contact-title" >{t('resumo')}</h2>
          </div>
          <div className="col-lg-8">
            {percentCompleted < 100 ? <Progress animated value={percentCompleted} /> : isValidToken === true ?
            <>
            <Table borderless>
              <thead>
                <tr>
                  <th>{t('plano')}</th>
                  <th>{t('quantidade')}</th>
                  <th>{t('valor')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="single_prising">
                      <div className="prising_header deep">
                        <h3>{cartInfo.title}</h3>
                      </div>
                      {/* <div className="middle_content">
                        <div className="list">
                          <ul>
                            {lista.map(item => (
                              <li>{t(item)}</li>
                            ))}
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </td>
                  <td>
                    <nav aria-label="Page navigation example">
                      <ul class="pagination" >
                        {!hasCupom && <li onClick={() => changeQuant('-')}  class="page-item"><p class="page-link" >-</p></li> }
                        <li class="page-item">
                            <input 
                              disabled={hasCupom} 
                              style={{width: 50, height: 36, border: "1px solid #dee2e6"}}
                              class=""
                              min="0" 
                              name="quantity" 
                              onChange={e => changeQuant("", e.target.value)}
                              value={cartInfo.quantidade} 
                              type="number"></input>
                        </li>
                        {!hasCupom && <li onClick={() => changeQuant('+')} class="page-item"><p class="page-link">+</p></li>}
                      </ul>
                    </nav>
                    </td>
                  <td>R$ {resumo.subtotal}</td>
                </tr>
              </tbody>
            </Table>
            {cartInfo.id_product === 2 ? 
            <div class="embed-responsive embed-responsive-16by9" style={{marginBottom: 20}}>
              <iframe 
                title="Plano START"
                class="embed-responsive-item" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                frameborder="0" 
                allowfullscreen
                src="https://www.youtube.com/embed/s8O2orsu7D0" >
              </iframe>
            </div> : cartInfo.id_product === 3 ?
            <div class="embed-responsive embed-responsive-16by9" style={{marginBottom: 20}}>
              <iframe 
                title="Plano OFFICER"
                class="embed-responsive-item" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                frameborder="0" 
                allowfullscreen
                src="https://www.youtube.com/embed/YE4AN-U8xnc" >
              </iframe>
            </div> : cartInfo.id_product === 4 ?
            <div class="embed-responsive embed-responsive-16by9" style={{marginBottom: 20}}>
              <iframe 
                title="Plano Leader"
                class="embed-responsive-item" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                frameborder="0" 
                allowfullscreen
                src="https://www.youtube.com/embed/YE4AN-U8xnc" >
              </iframe>
            </div> : null}
            {!isEmpresa &&
            <Form onSubmit={getDiscont}>
            <Label for="exampleEmail">{t('cupomDesconto')}</Label>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input type="text" name="cupom" onChange={e => setCupom(e.target.value)} placeholder={t('digiteSeuCupom')} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <Button color="primary">OK</Button>
              </Col>
            </Row>
          </Form>}
            
            </> : 
            <div className="col-12">
              <h2 className="contact-title" >{t('tokenInvalido')}</h2>
              <span>{t('irParaPagna')} <Link to="/planos">{t('planos')}</Link></span>
            </div>
           }
          </div>
          
          <div className="col-lg-3 offset-lg-1" style={{padding: 25, height: "50%", backgroundColor: "#f9f9f9"}}>
            <div className="media contact-info">
              {/* <span className="contact-info__icon"><i className="ti-angle-right"></i></span> */}
              <div className="d-flex justify-content-between media-body">
                <h3>{t('subtotal')}
                </h3>
                <p>R$ {resumo.subtotal}</p>
              </div>
            </div>
            <div className="media contact-info">
              {/* <span className="contact-info__icon"><i className="ti-minus"></i></span> */}
              <div className="d-flex justify-content-between media-body">
                <h3>{t('desconto')}</h3>
                <p>{desconto}%</p>
              </div>
            </div>
            <div className="media contact-info">
              {/* <span className="contact-info__icon"><i className="ti-receipt"></i></span> */}
              <div className="d-flex justify-content-between media-body">
                <h3>{t('total')}</h3>
                <p style={{color: "#52b12f", fontSize: 20}}>R$ {resumo.total}</p>
              </div>
            </div>
            <div className="media contact-info">
              {cartInfo !== null && isValidToken === true ? 
                <Button onClick={() => hendlePurchase()} color="primary" size="lg">{t('concluirPedido')}</Button>
              : <Button disabled={true} color="primary" size="lg">{t('concluirPedido')}</Button> }
            </div>
              
          </div> 
        </div>
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title" >{t('depoimentos')}</h2>
            </div>
            <div className="col-12">
              <CardColumns>
              {depoimentos === null ? <h1>{t('carregando')}</h1> :
                depoimentos.map((item, key)=> (
                  <CardDepoimento
                  key={key} 
                  ativo={item.publicado}
                  nome={item.nome} 
                  texto={item.texto}
                  data={item.data}/>
                ))
              }
              </CardColumns>
            </div>
          </div>
        </div>
    </section>
    <ModalInfo isOpen={modalInfo} toggle={handleModal} handleButton={handleModal} lebalButton={t('estaBem')}>
      <p>{erroMensagem}</p>
    </ModalInfo>
    <ModalInfo isOpen={modalInfoToken} toggle={handleModalToken} handleButton={() => history.push('/planos')} lebalButton={t('estaBem')}>
      <h1>{t('tokenInvalido')}</h1>
      <p>{t('tenteNovamente')}</p>
    </ModalInfo>
    <Footer/>
    </>
  )
}
